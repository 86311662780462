import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import FeaturedAuditsCard from "./FeaturedAuditsCard";
import axios from "axios";
import { Empty, Spin } from "antd";

function FeaturedAuditsSection() {
  const [isLoading, setIsLoading] = useState(false);
  const [auidts, setAudits] = useState();

  const fetchLatestThreeAudits = async () => {
    setIsLoading(true);
    try {
      console.log("responseaudits");
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}audits/latest`
      );

      console.log("responseaudits", response);
      if (response && response.status == 200) {
        console.log(response.data.payload);
        setAudits(response?.data?.payload);
      }
    } catch (error) {
      console.log("Error while fetch latest three audits ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLatestThreeAudits();
  }, []);

  return (
    <div className="mb-5">
      <Row>
        {isLoading ? (
          <Spin />
        ) : auidts?.length > 0 ? (
          auidts?.map((audit, key) => {
            return (
              <Col
                key={key}
                xxl="4"
                xl="4"
                lg="4"
                md="6"
                sm="12"
                xs="12"
                className="mt-2"
              >
                <FeaturedAuditsCard audit={audit} />
              </Col>
            );
          })
        ) : (
          <Empty />
        )}

        {/* <Col xxl="4" xl="4" lg="4" md="6" sm="12" xs="12" className="mt-2">
          <FeaturedAuditsCard />
        </Col>

        <Col xxl="4" xl="4" lg="4" md="6" sm="12" xs="12" className="mt-2">
          <FeaturedAuditsCard />
        </Col> */}
      </Row>
    </div>
  );
}

export default FeaturedAuditsSection;
