import { Col, Row } from 'antd'
import React from 'react'
import './Footer.css'
import rugfreelogo from '../../images/rfc_new_logo.svg'
import twitterIcon from '../../images/twitter-icon.svg'
import telegramIcon from '../../images/telegram-icon.svg'
import mailIcon from '../../images/email-icon.svg'
import { Link } from 'react-router-dom'

function FooterContent() {
  return (
    <>
      <div className='container footer-row-1 pb-4'>
        <div className='row'>
          <Row>
            <Col className='hide-on-mobile' xs={24} md={16}>
              <div className='footer-logo-desc-wrapper d-flex'>
                <img src={rugfreelogo} />
              </div>
              <span className='text-muted text-uppercase'>is the place to find the next big crypto coin</span>
            </Col>
            {/* <Col className='col-2' xs={24} md={8}>
              <h3 className='footer-col-title'>Our Offices</h3>
              <div className='menu-links'>
                <Link className='link' to={'#'}>UK</Link>
                <Link className='link' to={'#'}>Sri Lanka</Link>
                <Link className='link' to={'#'}>Ukraine</Link>
                <Link className='link' to={'#'}>Duabi</Link>
                <Link className='link' to={'#'}>USA</Link>
              </div>
            </Col> */}

            <Col className='hide-on-pc text-center' xs={24} md={16}>
              <img src={rugfreelogo} style={{width: 'auto', height: '45px'}} />
            </Col>

            
            <Col className='col-3 text-center' xs={24} md={8}>
              <h3 className='footer-col-title'>Social Links</h3>
              <div className='footer-social-links'>
                <a href='https://twitter.com/rugfreecoins' target="_blank" className='link'><img src={twitterIcon} style={{width: '25px'}} /></a>
                <a href="https://t.me/rugfreecoin" target="_blank" className='link'><img src={telegramIcon} style={{width: '25px'}} /></a>
                <a href='mailto:contact@rugfreecoins.com' className='link'><img src={mailIcon} style={{width: '25px'}} /></a>
              </div>
            </Col>
          </Row>
        </div>
      </div >
      <div className='container footer-row-2'>
        <div className='row'>
          <Row>
            <Col span={24}>
              <div className='copyright text-center text-md-left'>
                <span>COPYRIGHT © {new Date().getFullYear()} rugfreecoins.com</span>
              </div>
            </Col>

          </Row>
        </div>
      </div>
    </>
  )
}

export default FooterContent