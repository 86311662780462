import React from "react";

function ChainName(props) {
  const { tokenData } = props;

  const getChainName = () => {
    if (tokenData?.bsc_contract_address) {
      return <span className="my-auto">BSC</span>;
    } else if (tokenData?.ethereum_contract_address) {
      return <span className="my-auto">ETH</span>;
    } else if (tokenData?.tron_token) {
      return <span className="my-auto">TRON</span>;
    } else if (tokenData?.kcc_token) {
      return <span className="my-auto">KCC</span>;
    } else if (tokenData?.solana_token) {
      return <span className="my-auto">SOLANA</span>;
    } else if (tokenData?.polygon_token) {
      return <span className="my-auto">POLYGON</span>;
    } else if (tokenData?.avalanche_token) {
      return <span className="my-auto">AVALANCHE</span>;
    }
  };

  return getChainName();
}

export default ChainName;
