import React, { useEffect, useState } from "react";
import "./AuditTable.css";
import { Table, notification, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuditedTokens } from "../../Redux/AuditedTokensSlice";
import MarketCap from "../MarketCap/MarketCap";
import RiskLevel from "../RiskLevel/RiskLevel";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
import VoteButton from "../VoteButton/VoteButton";
import TokenName from "../TokenName/TokenName";
import Chain from "../Chain/Chain";
import TokensAuditIconsGroup from "../TokenAuditIconsGroup/TokensAuditIconsGroup";
import SearchBar from "../AuditPageComponents/SearchBar";

function TokensData() {
  // table data related variables
  const { loading, data, error } = useSelector((state) => state.auditedTokens);
  const dispatch = useDispatch();

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });

  // antd notifications without duplicating
  const notificationKey = "error";

  // fetch data from API
  useEffect(() => {
    dispatch(
      fetchAuditedTokens({
        current_page: 1,
        per_page: 20,
        direction: "DESC",
        sort_key: "vote_count",
      })
    );
  }, [dispatch]);

  //   useEffect(() => {
  //     dispatch(
  //       fetchAuditedTokens({
  //         current_page: tableParams.pagination.current,
  //         per_page: tableParams.pagination.pageSize,
  //         direction: "DESC",
  //         sort_key: "vote_count",
  //       })
  //     );
  //   }, [tableParams]);

  // error nofitications
  useEffect(() => {
    if (error) {
      notification["error"]({
        key: notificationKey,
        message: "Oops!",
        description: error,
      });
    }
  }, [error]);

  // columns related data
  const columns = [
    {
      key: "id",
      title: "No",
      dataIndex: "id",
      render: (_, record, index) =>
        index +
        1 +
        tableParams.pagination.pageSize * (tableParams.pagination.current - 1),
    },
    {
      key: "name",
      title: "Name",
      fixed: "left",
      dataIndex: "name",
      render: (_, record) => (
        <TokenName
          id={record.id}
          tokenName={record.name}
          tokenSymbol={record.symbol}
          logoLink={record.logo_link}
          coingecko_link={record.coingecko}
          coinmarketcap_link={record.coinmarketcap}
        />
      ),
      sorter: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      key: "chain",
      title: "Chain",
      dataIndex: "chain",
      render: (_, record, index) => <Chain tokenData={record} />,
    },
    {
      key: "market_cap",
      title: "Market Cap",
      render: (_, record) => (
        <span className="marketcap-text">
          <MarketCap
            address={
              record.bsc_contract_address
                ? record.bsc_contract_address
                : record.ethereum_contract_address
            }
            chain={record.bsc_contract_address ? "bsc" : "eth"}
          />
        </span>
      ),
    },
    {
      key: "risk_level",
      title: "Risk Level",
      // render: (_, record) => <TokensAuditIconsGroup />,
      render: (_, record) => <RiskLevel riskLevel={record.risk_level} />,
      sorter: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      key: "audit",
      title: "Audit",
      dataIndex: "audit",
      render: (_, record) => (
        <Button
          type="primary"
          size="middle"
          onClick={() => {
            window.open(record.report_link);
          }}
        >
          Audit Report
        </Button>
      ),
    },
    {
      key: "votes",
      title: "Votes",
      dataIndex: "vote_count",
      render: (_, record) => (
        <div>
          <VoteButton
            voteCount={record.vote_count}
            isVoted={record.is_voted}
            dispatchEvent={fetchAuditedTokens()}
            coinID={record.id}
          />
        </div>
      ),
      sorter: true,
      sortDirections: ["descend", "ascend"],
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    console.log("handleTableChange", filters);

    dispatch(
      fetchAuditedTokens({
        current_page: pagination.current,
        per_page: pagination.pageSize,
        direction: sorter.order === "descend" ? "DESC" : "ASC" ?? "DESC",
        sort_key: sorter?.column?.dataIndex ?? "vote_count",
      })
    );
  };

  const handleSearch = (value) => {
    dispatch(
      fetchAuditedTokens({
        current_page: tableParams.pagination?.current ?? 1,
        per_page: tableParams?.pagination?.pageSize ?? 10,
        direction:
          tableParams?.sorter?.order === "descend" ? "DESC" : "ASC" ?? "DESC",
        sort_key: tableParams?.sorter?.column?.dataIndex ?? "vote_count",
        search: value === undefined ? "" : value ?? "",
      })
    );
  };

  useEffect(() => {
    if (data.data) {
      setTableParams({
        pagination: {
          current: data.current_page,
          pageSize: data.per_page,
          total: data.total,
        },
      });
    }
  }, [data]);
  console.log("tableParams", data.current_page);

  return (
    <div className="table">
      <div className="table-responsive">
        <SearchBar setSearchTerm={handleSearch} loading={loading} />
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          //   rowKey={(record) => record.login.uuid}
          size="small"
          columns={columns}
          dataSource={data.data}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
}

export default TokensData;
