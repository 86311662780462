import React from 'react'
import AddHere from '../../Components/AddHere/AddHere'
import { Col, Row } from 'reactstrap'
import AirdropListTable from '../../Components/AirdropComponents/AirdropListTable'
import { Card } from 'antd'
import { DribbbleOutlined, StarFilled, StarOutlined } from '@ant-design/icons'
import './Watchlist.css'

function Watchlist() {
    return (
        <div className='container-lg'>
            <div className='page-header'>
                <AddHere />
                <Row>
                    <Col>
                        <h3 className="text-uppercase">Watchlist</h3>
                        <p>Follow your favorite coins and tokens!</p>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                        <Row className='mt-3'>
                            <Col xxl="1" xl="1" lg="1" md="1" sm="2" xs="2" className='watchlist-icon-margin'>
                                <DribbbleOutlined className='mt-1' style={{ fontSize: '36px', }} />
                            </Col>
                            <Col xxl="11" xl="11" lg="11" md="11" sm="10" xs="10">
                                <h6>Share your watchlist with this public link</h6>
                                <a href=''>https://rugfreecoins.com/watchlist/832408</a>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                        <Row className='mt-3'>
                            <Row>
                                <Col className='watchlist-star-icon-margin' xxl="1" xl="1" lg="1" md="1" sm="2" xs="2">
                                    <StarOutlined style={{ color: '#e6de00', fontSize: '20px' }} />
                                </Col>
                                <Col xxl="11" xl="11" lg="11" md="11" sm="10" xs="10">
                                    <h6>Add projects to your watchlist by clicking on the golden star</h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='watchlist-star-icon-margin' xxl="1" xl="1" lg="1" md="1" sm="2" xs="2">
                                    <StarFilled style={{ color: '#e6de00', fontSize: '20px' }} />
                                </Col>
                                <Col xxl="11" xl="11" lg="11" md="11" sm="10" xs="10">
                                    <h6>Watchlisted projects are directly visible, click on the star icon to remove it from your watchlist</h6>
                                </Col>
                            </Row>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className='home-tabs-card mb-5 mt-4'>
                            <AirdropListTable />
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Watchlist