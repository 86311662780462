import React, { useState } from 'react'
import AirdropListTable from './AirdropListTable'
import { Card, Tabs } from 'antd'
import { StarFilled, FireFilled, AuditOutlined, SafetyCertificateFilled, RocketFilled } from '@ant-design/icons'
import AuditTable from '../../Components/Tables/AuditTable'
// import { Col, Row } from 'reactstrap'


function AirdropTableTab() {

    const [searchTerm, setSearchTerm] = useState('')
    const [activeKey, setActiveKey] = useState('tab1')

    // const operations = <SearchBar setSearchTerm={setSearchTerm} />;

    const changeTab = activeKey => {
        setActiveKey(activeKey);
    };

    return (

        <>
            <Card className='home-tabs-card mb-5'>
                <Tabs tabBarExtraContent={activeKey === 'tab1'} type="card" defaultActiveKey='tab1' onChange={changeTab}>
                    <Tabs.TabPane tab={<div className='d-flex'><div style={{ marginTop: '-3px' }}><StarFilled /></div><span>All</span></div>} key='tab1'>
                        <AirdropListTable />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<div className='d-flex'><div style={{ marginTop: '-3px' }}><FireFilled /></div><span>Active</span></div>} key='tab2'>
                        <AirdropListTable />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<div className='d-flex'><div style={{ marginTop: '-3px' }}><RocketFilled /></div><span>Upcoming</span></div>} key='tab3'>
                        <AirdropListTable />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<div className='d-flex'><div style={{ marginTop: '-3px' }}><SafetyCertificateFilled /></div><span>Finished</span></div>} key='tab4'>
                        <AirdropListTable />
                    </Tabs.TabPane>
                </Tabs>
            </Card>

        </>

    )
}

export default AirdropTableTab