import React from "react";
import "./StatsArea.css";
import Marquee from "react-fast-marquee";

function StatsArea() {
  return (
    <div style={{ marginTop: "-2px" }} className="stats-area-bg">
      <div className="container">
        <div className="d-flex scollable-stat-area">
          <Marquee autoFill={true}>
            <div className="mx-2">
              <span className="text-muted">Tokens listed:</span>
              <span className="stats-highlighted-text"> 12,229</span>
            </div>

            <div className="mx-2">
              <span className="text-muted">Total votes:</span>
              <span className="stats-highlighted-text"> 294,490,274</span>
            </div>

            <div className="mx-2">
              <span className="text-muted">Registered users:</span>
              <span className="stats-highlighted-text"> 951,569</span>
            </div>

            <div className="mx-2">
              <span className="text-muted">Total Audits:</span>
              <span className="stats-highlighted-text"> 347</span>
            </div>

            <div className="mx-2">
              <span className="text-muted">Supported chains:</span>
              <span className="stats-highlighted-text"> 106</span>
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
}

export default StatsArea;
