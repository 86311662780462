import React from 'react'

function FeaturedAuditsSectionHeader() {
  return (
    <div>
      <h3 className="text-uppercase">Featured Audits</h3>
      {/* <p>Featured audits sub heading.</p> */}
      {/* <div className='divider'></div> */}
    </div>
  )
}

export default FeaturedAuditsSectionHeader