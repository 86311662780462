import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch data from api
export const fetchAllTokenList = createAsyncThunk('fetchAllTokenList', async (page_number) => {
    return api
        .get(`coins?per_page=10&direction=DESC&sort_key=vote_count&ip_address=null&page=${page_number}`)
        .then((response) => response.data.payload.data)
})

export const allTokenListSlice = createSlice({
    name: "allTokenList",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchAllTokenList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchAllTokenList.fulfilled, (state, action) => {
            state.data = action.payload
            state.error = ''
            state.loading = false
        })
        builder.addCase(fetchAllTokenList.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
    }
});

export default allTokenListSlice.reducer