import { Col, Row, Tabs } from 'antd'
import React, { useEffect } from 'react'
import CoinDescriptionCard from './CoinDescription/CoinDescriptionCard'
import './CoinDetails.css'
import CoinInformationCard from './CoinInformation/CoinInformationCard'
import Comments from './Comments/Comments'


function CoinDetailsOverview() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Row>
                <Col xs={{ span: 24 }} md={{ span: 16 }}>
                    <div>
                        <CoinDescriptionCard />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    <div>
                        <CoinInformationCard />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={{ span: 24 }} md={{ span: 16 }}>
                    <Comments />
                </Col>
            </Row>
        </>

    )
}

export default CoinDetailsOverview