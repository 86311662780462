import { Layout } from 'antd';
import React, { useState } from 'react';
import NavBar from './Components/NavBar/NavBar';
import Pages from './Pages/Pages';
import FooterContent from './Components/FooterContent/Footer';
import StatsArea from './Components/StatsArea/StatsArea';
import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useLocation } from 'react-router-dom';

function LayoutView() {

    const { Content, Footer } = Layout;
    const location = useLocation()
    const [hideNavBar, setHideNavBar] = useState(false)

    // AOS init
    useEffect(() => {
        AOS.init({
            once: true,
            useClassNames: true,
            duration: 700
        });
    }, [])

    useEffect(() => {
        if(location.pathname === '/login' || location.pathname === '/register') {
            setHideNavBar(true)
        } else {
            setHideNavBar(false)
        }
    }, [location])

    return (
        <Layout className="layout">

            {
                !hideNavBar &&
                <>
                    <div style={{position: 'fixed', zIndex: '999', left: '0', right: '0'}}>
                        <StatsArea />
                    </div>

                    <div style={{zIndex: '999'}}>
                        <NavBar />
                    </div>
                </>

            }


            <Content className='site-layout' style={{ minHeight: '100vh', paddingTop: '100px' }}>
                <Pages />
            </Content>

            <Footer className="">
                <FooterContent />
            </Footer>
        </Layout>
    )
}

export default LayoutView