import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

// initialize client IP from local storage
const clientIP = localStorage.getItem('rfc_client_ip')
    ? localStorage.getItem('rfc_client_ip')
    : null

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch data from api
export const fetchTokenData = createAsyncThunk('fetchTokenData', async (id) => {
    return api
        .get(`coin/${id}?ip_address=${clientIP}`)
        .then((response) => response.data.payload)
})

export const allTokenListSlice = createSlice({
    name: "tokenData",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchTokenData.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchTokenData.fulfilled, (state, action) => {
            state.data = action.payload
            state.error = ''
            state.loading = false
        })
        builder.addCase(fetchTokenData.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
    }
});

export default allTokenListSlice.reducer