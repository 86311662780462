
import React from 'react'
import { Button, Card, Form } from 'antd'
import './CreateAidropComponent.css'

function AirdropFinaliseAirdrop() {
    return (
        <>
            <Card className='home-tabs-card mt-5'>
                <Form
                    className='mt-3'
                    name="token_information"
                    // onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item>
                        <div className='text-center mt-4'>
                            <Button type="primary" >
                                Finalise Airdrop
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Card>

        </>
    )
}

export default AirdropFinaliseAirdrop