import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

// initialize client IP from local storage
const clientIP = localStorage.getItem("rfc_client_ip")
  ? localStorage.getItem("rfc_client_ip")
  : null;

const initialState = {
  loading: false,
  data: [],
  error: "",
};

// fetch data from api
export const fetchAuditedTokens = createAsyncThunk(
  "fetchAuditedTokens",
  async ({ current_page, per_page, direction, sort_key, search }) => {
    const searchText = search ? `&search=${search}` : "";
    return api
      .get(
        `coins/audited-coins?per_page=${per_page}&direction=${direction}&sort_key=${sort_key}&ip_address=${clientIP}&page=${current_page+searchText}`
      )
      .then((response) => response.data.payload);
  }
);

export const auditedTokensSlice = createSlice({
  name: "auditedTokens",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAuditedTokens.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAuditedTokens.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchAuditedTokens.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default auditedTokensSlice.reducer;
