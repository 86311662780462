import { Avatar, Col, Image, Row } from 'antd'
import React from 'react'
import AuditContactComponent from '../../Components/AuditPageComponents/AuditContactComponent'
import ListItem from '../../images/list-icon.svg'
import LineImage from '../../images/line.svg'
import CloseIcon from '../../images/close-icon-orange.png'

function AuditProcessPage() {
    return (
        <>
            <div className='container-fluid page-header'>
                <div className='row'>
                    <h1 className='page-header-title heading-1'>The Audit Process</h1>
                    <p className='page-header-description description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam volutpat, turpis eu venenatis vehicula, enim mi auctor diam, vel luctus magna libero quis mi. </p>
                </div>
                <div className='container mt-5'>
                    <div className='  d-flex flex-column justify-content-center'>
                        {/* <h5 className='text-color'>Smart Contracts For Tokens</h5> */}
                        <h2>Why should do an audit?</h2>
                    </div>
                    <div className='pt-2'>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                            the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                            type and scrambled it to make a type specimen book
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                            the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                            type and scrambled it to make a type specimen book
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                            the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                            type and scrambled it to make a type specimen book
                            <br />
                            <br />
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                            the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                            it to make a type specimen book</p>
                    </div>
                </div>
                <div className='container mt-5'>
                    <div className='  d-flex flex-column justify-content-center'>
                        {/* <h5 className='text-color'>Smart Contracts For Tokens</h5> */}
                        <h2>What type of audits do we offer?</h2>
                    </div>
                    <div className='pt-2'>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                            the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                            type and scrambled it to make a type specimen book</p>
                    </div>
                </div>
                <div className='container py-5'>
                    <Row>
                        <Col span={12}>
                            <div className='m-2'>
                                <h5 className='pb-2'>
                                    <Avatar className='m-1 audit-avatar' size="small" src={ListItem} />
                                    Audit for the projects which have not been launched
                                </h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                    the industry's standard dummy text ever</p>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='m-2'>
                                <h5 className='pb-2'>
                                    <Avatar className='m-1 audit-avatar' size="small" src={ListItem} />
                                    Audit for the projects which have launched
                                </h5>
                                <p >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                    the industry's standard dummy text ever</p>
                            </div>
                        </Col>
                    </Row>


                </div>

                <div className='container pt-5 pb-3'>
                    <h3 className='pb-2 pl-2'><Avatar className='audit-avatar' size="small" src={ListItem} />
                        Audit for the projects which have not been launched
                    </h3>
                </div>
                <div className='container'>
                    <Row>
                        <Col span={1}>
                            <Col>
                                <div>
                                    <Image src={LineImage} className='line-style1' />
                                </div>
                            </Col>
                        </Col>
                        <Col span={23}>
                            <div className='ml-5'>
                                <div className='py-3'>
                                    <h4 className='pb-2'>
                                        Step 1
                                    </h4>
                                    <p >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                        the industry's standard dummy text ever</p>
                                </div>
                                <div className='py-3'>
                                    <h4 className='pb-2'>
                                        Step 2
                                    </h4>
                                    <p >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                        the industry's standard dummy text ever</p>
                                </div>
                                <div className='py-3'>
                                    <h4 className='pb-2'>
                                        Step 3
                                    </h4>
                                    <p >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                        the industry's standard dummy text ever</p>
                                    <div className='mt-4 subline'>
                                        <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                            the industry's standard dummy text ever</p>
                                        <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                            the industry's standard dummy text ever</p>
                                        <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                            the industry's standard dummy text ever</p>
                                    </div>
                                </div>
                                <div className='py-3'>
                                    <h4 className='pb-2'>
                                        Step 4
                                    </h4>
                                    <p >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                        the industry's standard dummy text ever</p>
                                </div>
                                <div className='py-3'>
                                    <h4 className='pb-2'>
                                        Step 5
                                    </h4>
                                    <p >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                        the industry's standard dummy text ever</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='service-button stats-sections-orange row'>
                        <Col span={1}><Avatar className='mr-2 mt-4' size="small" src={CloseIcon} /></Col>
                        <Col span={23}><p className='orange-note-text p-2 m-2'> Do never pay anyone for a promotion on our platform, unless you have received a confirmation email from this address.
                            <br />Do never pay anyone for a promotion on our platform, unless you have received a confirmation email from this address. </p></Col>

                    </div>
                </div>

                <div className='container pt-5 pb-3'>
                    <h3 className='pb-2'><Avatar className='audit-avatar' size="small" src={ListItem} />
                        Audit for the projects which have launched
                    </h3>
                </div>

                <div className='container'>
                    <Row>
                        <Col span={1}>
                            <Col>
                                <div>
                                    <Image src={LineImage} className='line-style' />
                                </div>
                            </Col>
                        </Col>
                        <Col span={23}>
                            <div>
                                <div className='py-3'>
                                    <h4 className='pb-2'>
                                        Step 1
                                    </h4>
                                    <p >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                        the industry's standard dummy text ever</p>
                                </div>
                                <div className='py-3'>
                                    <h4 className='pb-2'>
                                        Step 2
                                    </h4>
                                    <p >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                        the industry's standard dummy text ever</p>
                                </div>
                                <div className='py-3'>
                                    <h4 className='pb-2'>
                                        Step 3
                                    </h4>
                                    <p >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                        the industry's standard dummy text ever</p>
                                    <div className='pt-3 pb-2'>
                                        <Row>
                                            <Col span={12}>
                                                <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </p>
                                                <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </p>
                                                <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </p>
                                                <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </p>
                                                <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </p>
                                            </Col>
                                            <Col span={12}>
                                                <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </p>
                                                <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </p>
                                                <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </p>
                                                <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </p>
                                                <p> <Avatar className='m-1 p-1 audit-avatar' size="small" src={ListItem} />Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </p>
                                            </Col>
                                        </Row></div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <AuditContactComponent />
        </>
    )
}

export default AuditProcessPage