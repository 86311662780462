import { Col, Row, Tabs } from 'antd'
import React, { useEffect } from 'react'
import EventDescriptionCard from './EventDescription/EventDescriptionCard'
import './EventDetails.css'
import EventInformationCard from './EventInformation/EventInformationCard'
import Comments from './Comments/EventComments'

function EventDetailsOverview() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Row>
                <Col xs={{ span: 24 }} md={{ span: 16 }}>
                    <div>
                        <EventDescriptionCard />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    <div>
                        <EventInformationCard />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={{ span: 24 }} md={{ span: 16 }}>
                    <Comments />
                </Col>
            </Row>
        </>

    )
}

export default EventDetailsOverview