import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

// initialize client IP from local storage
const clientIP = localStorage.getItem('rfc_client_ip')
    ? localStorage.getItem('rfc_client_ip')
    : null

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch data from api
export const fetchEventVoteCount = createAsyncThunk('fetchEventVoteCount', async (id) => {
    return api
        .get(`crypto-events/event/${id}/vote-count`)
        .then((response) => response.data.payload)
})

export const allVoteCount = createSlice({
    name: "eventVoteCount",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchEventVoteCount.pending, (state) => {
            state.voteCountLoading = true
        })
        builder.addCase(fetchEventVoteCount.fulfilled, (state, action) => {
            state.voteCountData = action.payload
            state.voteCountError = ''
            state.voteCountLoading = false
        })
        builder.addCase(fetchEventVoteCount.rejected, (state, action) => {
            state.voteCountLoading = false
            state.coutData = []
            state.voteCountError = action.error.message
        })
    }
});

export default allVoteCount.reducer