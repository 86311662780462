import { Card, Form, Button, Input, Divider, notification } from 'antd'
import React, { useEffect } from 'react'
import RugFreeCoinsLogo from '../../images/rugfreecoins.png'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import './auth.css'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser, reset } from '../../Redux/AuthSlice'

function Login() {

    // auth related variables
    const { loading, error, token, success} = useSelector((state) => state.auth) 
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        // Listen for changes in the browser history.
        const handlePopstate = () => {
            console.log(location.pathname)
            // Check if the user navigated back to the login page.
            if (location.pathname === '/login') {
            // Redirect the user to the home page.
                navigate('/');
            }
        };
    
        window.addEventListener('popstate', handlePopstate);
    
        // Clean up the event listener when the component unmounts.
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [navigate])

    // error nofitications
    useEffect(() => {
        if (error) {
            notification['error']({
                key: 'error',
                message: 'Oops!',
                description: error
            });

            dispatch(reset()) // reset error message 
        }
    }, [error])

    useEffect(() => {
        if(success) {
            notification['success']({
                key: 'success',
                message: 'Success',
                description: 'Successfully logged in!'
            });

            dispatch(reset()) // reset success message
            navigate('/profile') // navigate to home page
        }
    }, [token])

    const onFinish = (values) => {
        //creat the form request
        let formData = new FormData();
        formData.append("email", values.email);
        formData.append("password", values.password);
        dispatch(loginUser(formData))
    };

    return (
        <div>
            <div className='container col-lg-4 col-md-5 col-xs-12 mx-auto center'>
                <Card className='home-tabs-card'>
                    <div className='text-center mb-4'>
                        <img src={RugFreeCoinsLogo} alt="rugfreecoins-logo" className='rugfree-logo' />
                    </div>

                    <div className='mb-4'>
                        <h5>Welcome to Rug Free Coins 👋</h5>
                        <span className='text-muted'>Please sign-in to your account and start the adventure</span>
                    </div>

                    <Form
                        name="login"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <span className='small'>Email</span>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The Email field must be a valid email!'
                                },
                                {
                                    required: true,
                                    message: 'The Email field is required!',
                                },
                            ]}
                        >
                            <Input 
                                placeholder='john@example.com'
                                size="large" 
                            />
                        </Form.Item>
                        
                        <span className='small'>Password</span>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                required: true,
                                message: 'The Password field is required!',
                                },
                            ]}
                        >
                            <Input.Password
                                placeholder='Password'
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="large" className='col-12' loading={loading}>
                                Sign in
                            </Button>
                        </Form.Item>
                    </Form>

                    <Divider>
                        <span className='small fw-normal'>
                            or
                        </span>
                    </Divider>

                    <div className='text-center'>
                        <p>New on our platform? <Link to="/register" className='text-active'> Create an account</Link></p>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default Login