import React from 'react'
import {Link} from 'react-router-dom'
import { Table, Tag } from 'antd';
import '../EventTabs.css'
import EventData from '../EventData.json'

function HottestTab() {
  const columns = [
    {
      title: 'Event',
      dataIndex: 'eventName',
      key: 'event',
      className: 'event-name-cell',
      render: (_, record) => (
        <div className='event-info'><img className='event-logo' src={record.eventImg} /><span className='event-name-text'>{record.eventName}</span></div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'eventDate',
      key: 'date',
    },
    {
      title: 'Location',
      dataIndex: 'eventLocation',
      key: 'location',
    },
    {
      title: 'Price',
      key: 'eventPrice',
      dataIndex: 'eventPrice',
      render: text => (
        (text === 'free') ? <Tag color='green'>{text}</Tag> : <Tag color='gold'>{text}</Tag>
      )
    },
    {
      title: 'Website',
      key: 'weeventWebsitebsite',
      dataIndex: 'eventWebsite',
      render: (text) => <a className='event-website' href={`${text}`} target="_blank" rel="noopener noreferrer">Website</a>,
    },
  ];



  return (
    <Table className='events-table' columns={columns} dataSource={EventData} pagination={false} />
  )
}

export default HottestTab