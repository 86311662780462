import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Tag, notification, Button, Pagination } from 'antd';
import '../EventTabs.css'
import EventData from '../EventData.json'
import moment from 'moment';

import { fetchUpcomingEvents } from '../../../../Redux/UpcomingEventsSlice';

function UpcomingTab(props) {

  // table data related variables
  const { loading, data, error } = useSelector((state) => state.upComingEvents)
  const { searchTerm } = props
  const dispatch = useDispatch()

  const dataSource = data?.data

  // pagination related data
  const [pageNumber, setPageNumber] = useState(1)


  // antd notifications without duplicating
  const notificatioKey = 'error';


  // fetch data from API
  useEffect(() => {
    let searchObject = {
      pageNumber: pageNumber,
      searchTerm: searchTerm
    }
    dispatch(fetchUpcomingEvents(searchObject))
  }, [dispatch, pageNumber, searchTerm])

  // error nofitications
  useEffect(() => {
    if (error) {
      notification['error']({
        key: notificatioKey,
        message: 'Oops!',
        description: error
      });
    }
  }, [error])

  const getColumns = () => {
    const columns = [
      {
        title: 'No.',
        dataIndex: 'rowNumber',
        key: 'rowNumber'
      },
      {
        title: 'Event',
        dataIndex: 'name',
        key: 'name',
        className: 'event-name-cell',
        render: (_, record) => (
          <div className='event-info'>
            <Link to={record.id && `/event-details/${record.id}`}>
            </Link>
            <span className='event-name-text'>{record.name}</span>
          </div>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'start_datetime',
        key: 'date',
        render: (_, record) => (
          <div>
            <Link to={record.id && `/event-details/${record.id}`}>
            </Link>
            <span>{moment(record.start_datetime).format('MMMM DD, YYYY')}</span>
          </div>
        )
      },
      {
        title: 'Location',
        dataIndex: 'location_name',
        key: 'location',
        render: (_, record) => (
          <div>
            <Link to={record.id && `/event-details/${record.id}`}>
            </Link>
            <span>{record?.location_name}</span>
          </div>
        )
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price',
        render: (_, record) => (
          <div>
            <Link to={record.id && `/event-details/${record.id}`}>
            </Link>
            <span>{record.text == 0 ? <Tag color='green'>free</Tag> : <Tag color='gold'>paid</Tag>}</span>
          </div>
        )
      },
      {
        title: 'Website',
        key: 'weeventWebsitebsite',
        dataIndex: 'eventWebsite',
        className: 'event-website-table-cell',
        render: (_, record) => (
          <a className='event-website' href={record.website} target="_blank" rel="noopener noreferrer">Website</a>
        )
        // render: text => (<a className='event-website' href={text} target="_blank" rel="noopener noreferrer">Website</a>),
      },
    ];

       // Add a conditional check for dataSource
       const dataSourceWithRowNumbers = dataSource?.map((item, index) => ({
        ...item,
        rowNumber: (pageNumber - 1) * 10 + index + 1
      }));
  
      return columns.map((column) => {
        if (column.key === 'rowNumber') {
          return {
            ...column,
            render: (_, __, index) => dataSourceWithRowNumbers?.[index]?.rowNumber
          };
        }
        return column;
      });
  }

  const columns = [
    {
      title: 'No.',
      key: 'rowNumber',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Event',
      dataIndex: 'name',
      key: 'name',
      className: 'event-name-cell',
      render: (_, record) => (
        <div className='event-info'>
          <Link to={record.id && `/event-details/${record.id}`}>
          </Link>
          <span className='event-name-text'>{record.name}</span>
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'start_datetime',
      key: 'date',
      render: (_, record) => (
        <div>
          <Link to={record.id && `/event-details/${record.id}`}>
          </Link>
          <span>{moment(record.start_datetime).format('MMMM DD, YYYY')}</span>
        </div>
      )
    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      key: 'location',
      render: (_, record) => (
        <div>
          <Link to={record.id && `/event-details/${record.id}`}>
          </Link>
          <span>{record?.location_name}</span>
        </div>
      )
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
      render: (_, record) => (
        <div>
          <Link to={record.id && `/event-details/${record.id}`}>
          </Link>
          <span>{record.text == 0 ? <Tag color='green'>free</Tag> : <Tag color='gold'>paid</Tag>}</span>
        </div>
      )
    },
    {
      title: 'Website',
      key: 'weeventWebsitebsite',
      dataIndex: 'eventWebsite',
      className: 'event-website-table-cell',
      render: (_, record) => (
        <a className='event-website' href={record.website} target="_blank" rel="noopener noreferrer">Website</a>
      )
      // render: text => (<a className='event-website' href={text} target="_blank" rel="noopener noreferrer">Website</a>),
    },
  ];


  return (
    <>
      <Table rowKey={(record) => record.id} className='events-table table' columns={getColumns()} dataSource={dataSource} loading={loading} pagination={false} />
      <div className='d-flex w-100 justify-content-center'>
      <Pagination
      current={pageNumber}
      total={data?.total} // total number of items
      pageSize={10} // number of items per page
      onChange={(page) => setPageNumber(page)}
    />
    </div>
    </>
  )
}

export default UpcomingTab