import React, { useState } from 'react'
import { Button, List, Steps, } from 'antd'
// import { Col, Row } from 'reactstrap'
import './CreateAirdrop.css'

//Airdrop components
import AirdropTokenInformation from '../../Components/AirdropComponents/AirdropTokenInformation';
import DepositTokens from '../../Components/AirdropComponents/AirdropDepositTokens';
import FinaliseAirdrop from '../../Components/AirdropComponents/FinaliseAirdrop'
import { Col, Row } from 'reactstrap';
import AddHere from '../../Components/AddHere/AddHere';


// const { Step } = Steps;

const steps = [
    { title: 'Fill in the form', head: 'Step', content: <AirdropTokenInformation /> },
    // { title: 'Deposit Tokens to the Airdrop Contract', head: 'Step', content: <DepositTokens /> },
    // { title: 'Finalise your Airdrop!', head: 'Step', content: <FinaliseAirdrop /> },
];


function CreateAirdrop() {

    const [currentStep, setCurrentStep] = useState(0);

    // const handleNext = () => {
    //     setCurrentStep(currentStep + 1);
    // };

    // const handleBack = () => {
    //     setCurrentStep(currentStep - 1);
    // };

    const data = [
        '1. Creating an Airdrop with RugfreeCoins costs 0.2 BNB Flat Fee + 0.01 BNB per 100 winners.',
        '2. You will create your own Airdrop, and then deposit tokens to the smart contract.',
        '3. When the Aidrop expires, RugfreeCoins will announce the winners and they will be able to claim the rewards through the website.',
        '4. RugfreeCoins will provide you the list of all the participants.',
    ];

    // const data = [
    //     {
    //         title: 'Bullet Point 1',
    //         content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    //     },
    //     {
    //         title: 'Bullet Point 2',
    //         content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    //     },
    //     {
    //         title: 'Bullet Point 3',
    //         content: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    //     },
    // ];


    return (
        <div className='container-lg'>
            <div className='page-header'>
                <AddHere />
                <Row>
                    <Col>
                        <div className='mb-4'>
                            <h3 className="text-uppercase">Organize an Airdrop in RugfreeCoins</h3>
                            <List
                                // size="large"
                                itemLayout="vertical"
                                dataSource={data}
                                renderItem={(item) =>
                                    <List.Item
                                        style={{
                                            border: "none",
                                            paddingLeft: "20px",
                                            lineHeight: "1.2",
                                            textIndent: "-10px"
                                        }}>{item}
                                    </List.Item>}
                            />
                        </div>

                        <>
                            <div className='mb-5'>
                                {/* <Steps current={currentStep}>
                                    {steps.map((step) => (
                                        <Step key={step.title}
                                            title={step.head}
                                        />
                                    ))}
                                </Steps> */}
                                <div style={{ marginTop: '30px' }}>
                                    <h3 style={{ marginBottom: '20px' }}>{steps[currentStep].title}</h3>
                                    {steps[currentStep].content}
                                </div>
                                {/* <div style={{ marginTop: '30px' }}>
                                    {currentStep > 0 && (
                                        <Button style={{ marginRight: '8px' }} onClick={handleBack}>
                                            Back
                                        </Button>
                                    )}
                                    {currentStep < steps.length - 1 && (
                                        <Button type="primary" onClick={handleNext}>
                                            Next
                                        </Button>
                                    )}
                                    {currentStep === steps.length - 1 && (
                                        <Button type="primary" onClick={() => console.log('Form submitted!')}>
                                            Submit
                                        </Button>
                                    )}
                                </div> */}
                            </div>
                        </>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default CreateAirdrop