import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Spin } from 'antd'
import NumberFormat from 'react-number-format';

function MarketCap(props) {

    const { address, chain } = props
    const [loading, setLoading] = useState(false)
    const [marketCap, setMarketCap] = useState(0.0)

    useEffect(() => {
        const fetchMarketCap = async () => {
            setLoading(true)
            try {
                if (chain === 'bsc') {
                    await axios.get(`https://qpkjpvpeqx.us-east-1.awsapprunner.com/api/v1/contract-events/token-metadata/${address}`)
                    .then(res => {
                        if (res.status === 200) {
                            setMarketCap(res.data.payload.tokenMetadata.tokenMarketCap)
                        }
                    })
                } else {
                    await axios.get(`https://qpkjpvpeqx.us-east-1.awsapprunner.com/api/v1/contract-events/eth/token-metadata/${address}`)
                    .then(res => {
                        if (res.status === 200) {
                            setMarketCap(res.data.payload.tokenMetadata.tokenMarketCap)
                        }
                    })
                }
                
            } catch (error) {
                console.log('error fetching coin market cap ', error)
                setMarketCap(0.0)
            }
            setLoading(false)
        }

        fetchMarketCap()
    }, [address])

    return (
        <div>
            {
                loading ?
                    <Spin size='small' />
                    :
                    <NumberFormat
                        displayType='text'
                        decimalScale={0}
                        value={marketCap ? marketCap : 0}
                        prefix="$"
                        thousandSeparator={true}
                    />
            }
        </div>
    )
}

export default MarketCap