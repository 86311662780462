import { MinusOutlined } from '@ant-design/icons'
import { Col, Row, Tooltip } from 'antd'
import React from 'react'
// import AuditBadge from '../../images/audit-badge.svg'
import AuditBadge from '../../images/audit.png'
// import SafuBadge from '../../images/safu-badge.svg'
import SafuBadge from '../../images/fire-2.png'
import KycBadge from '../../images/kyc-badge.svg'
// import box from '../../images/box.png'

function Badges(props) {

    const { isAudited, riskLevel } = props

    return (
        <div className='d-flex'>
            { 
                isAudited === 1 ? 
                    <Tooltip title="Audit Badge">
                        <img src={AuditBadge} alt="Audit-Badge" style={{ height: '25px', width: '25px' }} />
                    </Tooltip>
                    :
                    <Tooltip title="Audit Badge">
                        <MinusOutlined style={{marginTop: '3px', width: '25px'}} />
                    </Tooltip>
            }

            { riskLevel === 'SAFU' ?
                <Tooltip title="SAFU Badge">
                    <img src={SafuBadge} alt="Safu-Badge" style={{ height: '25px', width: '25px' }} />
                </Tooltip>
                :
                <Tooltip title="SAFU Badge">
                    <MinusOutlined style={{marginTop: '3px', width: '25px'}} />
                </Tooltip>
            }
            {/* <div><img src={KycBadge} alt="Kyc-Badge" style={{ height: '22px', width: '25px' }} /></div> */}
        </div>
    )
}

export default Badges