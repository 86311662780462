import React from "react";
import bscLogo from "../../images/chain/bsc.png";
import ethLogo from "../../images/chain/eth.png";
import tronLogo from "../../images/chain/tron.png";
import kccLogo from "../../images/chain/kcc.png";
import solanaLogo from "../../images/chain/solana.png";
import polygonLogo from "../../images/chain/polygon.png";
import avalancheLogo from "../../images/chain/avalanche.png";
import tonLogo from "../../images/chain/ton.png";
import suiLogo from "../../images/chain/sui.png";

function Chain(props) {
  const { tokenData } = props;

  const getChainLogo = () => {
    if (tokenData?.bsc_contract_address) {
      return <img src={bscLogo} alt="bsc" className="p-1 token-logo" />;
    } else if (tokenData?.ethereum_contract_address) {
      return <img src={ethLogo} alt="eth" className="p-1 token-logo" />;
    } else if (tokenData?.tron_token) {
      return <img src={tronLogo} alt="tron" className="p-1 token-logo" />;
    } else if (tokenData?.kcc_token) {
      return <img src={kccLogo} alt="kcc" className="p-1 token-logo" />;
    } else if (tokenData?.solana_token) {
      return <img src={solanaLogo} alt="solana" className="p-1 token-logo" />;
    } else if (tokenData?.polygon_token) {
      return <img src={polygonLogo} alt="polygon" className="p-1 token-logo" />;
    } else if (tokenData?.avalanche_token) {
      return (
        <img src={avalancheLogo} alt="avalanche" className="p-1 token-logo" />
      );
    } else if (tokenData?.ton_token) {
      return (
        <img src={tonLogo} alt="avalanche" className="p-1 token-logo" />
      );
    } else if (tokenData?.sui_token) {
      return (
        <img src={suiLogo} alt="avalanche" className="p-1 token-logo" />
      );
    }
  };

  return getChainLogo();
}

export default Chain;
