import { Button, Card, notification } from "antd";
import React, { useState, useEffect } from "react";
import "./CoinDescriptionCard.css";
import StarIcon from "../../../images/star.svg";
import {
  FireFilled,
  FileProtectOutlined,
  RedditCircleFilled,
} from "@ant-design/icons";
import Badges from "../../Badges/Badges";
import { Row, Col } from "reactstrap";
import Web from "../../../images/sm-icon/web.svg";
import Telegram from "../../../images/sm-icon/telegram.svg";
import Twitter from "../../../images/sm-icon/twitter.svg";
import Discord from "../../../images/sm-icon/discord.svg";
import Pancakeswap from "../../../images/PancakeSwap-Logo.png";
import infoIcon from "../../../images/info.svg";
import fallbackCoinImg from "../../../images/no-img.png";
import parse from "html-react-parser";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTokenData } from "../../../Redux/TokenDataSlice";
import api from "../../../Services/Api";
import Address from "../../Address/Address";

function CoinDescriptionCard() {
  const { id } = useParams();
  const { loading, data, error } = useSelector((state) => state.tokenData);
  console.log("data>>>", data);
  const [imgSrc, setImgSrc] = useState(fallbackCoinImg);
  const dispatch = useDispatch();
  const [voteButtonClass, setVoteButtonClass] = useState(
    "vote-button-info-card"
  );
  const clientIP = localStorage.getItem("rfc_client_ip")
    ? localStorage.getItem("rfc_client_ip")
    : null;
  const [isLoading, setIsLoading] = useState(false);
  // antd notifications without duplicating
  const notificatioKey = "error";
  const [isAudited, setIsAudited] = useState(false);
  const [isHodlDescription, setIsHodlDescription] = useState(false);
  const [auditReportUrl, setAuditReportUrl] = useState("");

  // fetch data from API
  useEffect(() => {
    if (data?.report_link) {
      setAuditReportUrl(data?.report_link);
      setIsAudited(true);
    } else {
      setIsAudited(false);
    }
  }, [data]);

  // error nofitications
  useEffect(() => {
    if (error) {
      notification["error"]({
        key: notificatioKey,
        message: "Oops!",
        description: error,
      });
    }
  }, [error]);

  useEffect(() => {
    if (data?.is_voted) {
      setVoteButtonClass("vote-button-info-card-voted");
    } else {
      setVoteButtonClass("vote-button-info-card");
    }
  }, [data?.is_voted]);

  useEffect(() => {
    setImgSrc(data?.logo_link);
  }, [data?.logo_link]);

  const handleImgError = () => {
    setImgSrc(fallbackCoinImg);
  };

  // if not voted, trigger vote fuction and then reload the table
  const handleVote = async () => {
    if (!data?.is_voted && clientIP) {
      setIsLoading(true);

      try {
        await api
          .post(`coin/${data?.id}/vote`, { client_ip: clientIP })
          .then((response) => {
            if (response) {
              dispatch(fetchTokenData(id)); // reload
            }
          });
      } catch (error) {
        console.log("ERROR on casting vote action", error);
      }

      setIsLoading(false);
    }
  };

  return (
    <div className="m-2">
      <Card className="coin-description-card mb-5" loading={loading}>
        <div className="mb-2">
          <Row>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <div className="d-flex">
                <img
                  src={imgSrc}
                  alt="CoinIcon"
                  className="card-icon-tc"
                  onError={handleImgError}
                />
                <div className="mt-3">
                  <h5>{data?.name}</h5>
                  <p className="subtext1">{data?.symbol}</p>
                </div>

                <div>
                  {isAudited ? (
                    <div className="d-md-flex justify-content-center mx-3 mt-3">
                      <a href={auditReportUrl} target="_blank" rel="noreferrer">
                        <Button className="audit-report-button">
                          <FileProtectOutlined /> Audit report{" "}
                        </Button>
                      </a>
                    </div>
                  ) : (
                    <>{auditReportUrl}</>
                  )}
                </div>
              </div>
            </Col>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <div className="text-md-end">
                <Address tokenData={data} />
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-3 coin-descp-msg">
          <span>
            When you get 300 votes, please pin the link in your group, and
            <span className="coin-descp-msg-text">Contact Us</span> , you will
            receive a free promotion of 24 hours
          </span>
        </div>
        <div className="mt-3">
          <Row>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12" className="mt-3">
              <div className="mt-1">
                {data?.website && (
                  <a href={data?.website} target="_blank" rel="noreferrer">
                    <img className="sm-icon" src={Web} alt="" />
                  </a>
                )}
                {data?.telegram_link && (
                  <a
                    href={data?.telegram_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="sm-icon" src={Telegram} alt="" />
                  </a>
                )}
                {data?.twitter_link && (
                  <a href={data?.twitter_link} target="_blank" rel="noreferrer">
                    <img className="sm-icon" src={Twitter} alt="" />
                  </a>
                )}
                {data?.discord_link && (
                  <a href={data?.discord_link} target="_blank" rel="noreferrer">
                    <img className="sm-icon" src={Discord} alt="" />
                  </a>
                )}
                {data?.pancake_swap_link && (
                  <a
                    href={data?.pancake_swap_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="sm-icon"
                      src={Pancakeswap}
                      alt=""
                      style={{ width: "25px", height: "25px" }}
                    />
                  </a>
                )}
                {data?.reddit_link && (
                  <a href={data?.reddit_link} target="_blank" rel="noreferrer">
                    <RedditCircleFilled
                      style={{
                        fontSize: "22px",
                        color: "#ff4500",
                        backgroundColor: "white",
                        borderRadius: "100%",
                      }}
                    />
                  </a>
                )}
                {/* { isHodlReddit ? <a href='https://www.reddit.com/r/HodlToken' target="_blank"><RedditCircleFilled style={{fontSize: '22px', color: '#ff4500', backgroundColor: 'white', borderRadius: '100%'}} /></a> : <></>} */}
              </div>
            </Col>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <div className="d-md-flex justify-content-end">
                <Button
                  className={`d-flex mt-3 ${voteButtonClass}`}
                  style={{ marginRight: "5px" }}
                  onClick={() => handleVote()}
                  loading={isLoading}
                >
                  <div style={{ marginTop: "-2px", marginRight: "5px" }}>
                    <FireFilled
                      style={{ fontSize: "14px", color: "#FFA31A" }}
                    />
                  </div>
                  {data?.vote_count}
                </Button>
                <Button
                  style={{ marginRight: "5px" }}
                  className="mt-3 add-to-watchlist-info-card"
                >
                  <img src={StarIcon} alt="Star" className="star-icon" /> Add to
                  watchlist
                </Button>
                <div style={{ marginTop: "18px" }}>
                  <Badges
                    isAudited={data?.is_audited}
                    riskLevel={data?.risk_level}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-4">
          <div className="horizontal-line"></div>
        </div>
        {isHodlDescription ? (
          <div className="mt-3">
            Discover $HODL: The Proven Reward Token for Crypto Enthusiasts
            <br />
            <br />
            Embrace the power of $HODL, the established king of reward tokens on
            the Binance Smart Chain (BSC). Launched in May 2021, this
            deflationary asset has delivered over 20,000 BNB in rewards to its
            holders, revolutionizing passive income in the crypto space.
            <br />
            <br />
            $HODL's innovative 10% transaction tax fuels its robust reward
            system, allocating 5% to the reward pool, 2% as $HODL reflections,
            1.5% to the PancakeSwap liquidity pool, 0.5% to a lottery pool, and
            1% for operational costs. Claim rewards every 7 days or stack them
            to offset network costs, ensuring hassle-free passive income for all
            investors.
            <br />
            <br />
            Built to last, $HODL is hyperdeflationary, with close to 1% of every
            transaction being burned. Its automated lottery boosts deflation,
            while its sustainable reward pool guarantees stable payouts. Named
            after the iconic crypto term, $HODL enjoys a massive marketing
            advantage and inherent search volume.
            <br />
            <br />
            The $HODL ecosystem is ever-expanding, with future developments
            including the HODL App, HODL Pay, and opportunities to reinvest
            rewards into NFTs and more. Dive into the world of $HODL and secure
            your share of the most innovative and advanced token in the DeFi
            space!
          </div>
        ) : (
          <div className="mt-3">
            {data?.description && parse(data?.description)}
          </div>
        )}

        <div className="mt-5">
          <span>
            <img src={infoIcon} alt="Infomations" />
            <span> Information incorrect? Please submit an</span>
            <Link
              style={{ color: "#F49200" }}
              to="https://t.me/RugFreeCoins_Marketing"
              target="_blank"
            >
              {" "}
              Update Request!
            </Link>
          </span>
        </div>
      </Card>
    </div>
  );
}

export default CoinDescriptionCard;
