import { Button, Table } from 'antd'
import React, { useState, useEffect } from 'react'
import AirdropName from '../../Components/AirdropComponents/AirdropName'
import { Row } from 'reactstrap';
import './AirdropListTable.css'

function AirdropListTable() {

    const [buttonName, setButtonName] = useState('Join');

    const ActionHandler = () => {
        if (buttonName === 'Join') {
            setButtonName('Finished');
        } else {
            setButtonName('Join');
        }
    }


    useEffect(() => {
        ActionHandler();
    }, []); // 👈️ empty dependencies array

    // columns related data
    const columns = [
        {
            key: "id",
            title: 'No',
            dataIndex: "id",
            render: (_, record, index) => (index + 1),
        },
        // {
        //     key: "icon",
        //     title: 'Icon',
        //     dataIndex: "icon",
        //     render: (_, record, index) => (index + 1),
        // },
        {
            key: "name",
            title: 'Name',
            fixed: 'left',
            dataIndex: "name",
            render: (_, record) => (
                <AirdropName id={record.key} tokenName={record.name} tokenSymbol={record.symbol} logoLink={record.logo_link} coingecko_link={record.coingecko} coinmarketcap_link={record.coinmarketcap} />

            ),
        },
        {
            key: "start_date",
            title: 'Start Date',
            fixed: 'left',
            dataIndex: "start_date",
            render: (_, record) => (
                <div className='mt-3'>
                    <p>{record.start_date}</p>
                </div>
            ),
        },
        {
            key: "end_date",
            title: 'End Date',
            fixed: 'left',
            dataIndex: "end_date",
            render: (_, record) => (
                <div className='mt-3'>
                    <p>{record.end_date}</p>
                </div>
            ),
        },
        {
            key: "total_tokens",
            title: 'Total Tokens',
            fixed: 'left',
            dataIndex: "total_tokens",
            render: (_, record) => (
                <div className='mt-3'>
                    <p>{record.total_tokens}</p>
                </div>
            ),
        },
        {
            key: "participants",
            title: 'Participants',
            fixed: 'left',
            dataIndex: "participants",
            render: (_, record) => (
                <div className='mt-3'>
                    <p>{record.participants}</p>
                </div>
            ),
        },
        {
            key: "winners",
            title: 'Winners',
            fixed: 'left',
            dataIndex: "winners",
            render: (_, record) => (
                <div className='mt-3'>
                    <p>{record.winners}</p>
                </div>
            ),
        },
        {
            key: "finished",
            // title: 'Winners',
            fixed: 'left',
            dataIndex: "finished",
            render: (_, record) => (
                <div>
                    <Button onClick={ActionHandler} className='finished-button'>{buttonName}</Button>
                </div>
            ),
        },
    ]

    const dataSource = [
        {
            key: '1',
            name: 'EverEarn',
            age: 32,
            address: '10 Downing Street',
            logo_link: 'https://pbs.twimg.com/profile_images/1512554693511327754/_qF38P7G_400x400.jpg',
            coingecko: '',
            coinmarketcap: '',
            symbol: 'EARN',
            start_date: '2023/06/12',
            end_date: '2024/03/05',
            total_tokens: '4375983',
            participants: '2783',
            winners: '987539348'
        },
        {
            key: '2',
            name: 'HODL',
            age: 42,
            address: '10 Downing Street',
            logo_link: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9900.png',
            coingecko: '',
            coinmarketcap: '',
            symbol: 'HODL',
            start_date: '2023/10/12',
            end_date: '2024/08/05',
            total_tokens: '84759',
            participants: '93485',
            winners: '987539348'
        },
        {
            key: '3',
            name: 'HALO FAN TOKEN',
            age: 42,
            address: '10 Downing Street',
            logo_link: 'https://pbs.twimg.com/profile_images/1517459306454700032/KtyY6ecL_400x400.jpg',
            coingecko: '',
            coinmarketcap: '',
            symbol: 'HFT',
            start_date: '2023/01/12',
            end_date: '2024/01/15',
            total_tokens: '64',
            participants: '234',
            winners: '234'
        },
        {
            key: '4',
            name: 'Thoream V2',
            age: 42,
            address: '10 Downing Street',
            logo_link: 'https://i.ibb.co/jzYWb6H/photo-2022-01-17-13-27-09.jpg',
            coingecko: '',
            coinmarketcap: '',
            symbol: 'THOREAM',
            start_date: '2023/70/12',
            end_date: '2024/09/05',
            total_tokens: '8479',
            participants: '9345',
            winners: '9875348'
        },
        {
            key: '5',
            name: 'HODLX',
            age: 42,
            address: '10 Downing Street',
            logo_link: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19636.png',
            coingecko: '',
            coinmarketcap: '',
            symbol: 'HODLX',
            start_date: '2023/06/12',
            end_date: '2025/08/05',
            total_tokens: '854759',
            participants: '933485',
            winners: '98753933248'
        },

    ];

    return (
        <div className='table'>
            <div>
                <div className='table-responsive hide-on-pc'>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                        size='small'
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                    // loading={loading}
                    />
                </div>
                <div className='hide-on-mobile'>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                        size='small'
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                    // loading={loading}
                    />
                </div>
                <div className='text-center'>
                    <div className='service-button'>
                        <Button
                            type="primary"
                        >
                            See More
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AirdropListTable