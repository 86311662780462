import React from 'react';
import { Input, Space } from 'antd';
import { useSelector } from 'react-redux'

function SearchBar(props) {
    const { Search } = Input;
    const { setSearchTerm } = props;
    const { loading } = useSelector((state) => state.allTimeBestTokens)

    const onSearch = (value) => {
        setSearchTerm(value)
    }

    return (
        <Space direction="vertical">
            <Search placeholder="Search coins" onSearch={onSearch} enterButton loading={loading} allowClear size="large" />
        </Space>
    );
}

export default SearchBar;
