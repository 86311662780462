import { Card, Tag, notification } from 'antd'
import React, { useState, useEffect } from 'react'
import './EventInformationCard.css'
import GreenEye from '../../../images/green-eye.svg'
import BlueEye from '../../../images/blue-eye.svg'
import fallbackCoinImg from '../../../images/no-img.png'
import MarketCap from '../../MarketCap/MarketCap'
import moment from 'moment'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { fetchCryptoEventData } from '../../../Redux/EventDataSlice'

function EventInformationCard(props) {

    const { id } = useParams()
    const { loading, data, error } = useSelector((state) => state.eventData)
    const [imgSrc, setImgSrc] = useState(fallbackCoinImg)
    const dispatch = useDispatch()

    // antd notifications without duplicating
    const notificatioKey = 'error';

    // fetch data from API
    useEffect(() => {
        if(id) {
            dispatch(fetchCryptoEventData(id))
        }
    }, [id])

    // error nofitications
    useEffect(() => {
        if (error) {
            notification['error']({
                key: notificatioKey,
                message: 'Oops!',
                description: error
            });
        }
    }, [error])

    // useEffect(() => {
    //     // setImgSrc(data?.logo_link)
    // }, [data?.logo_link])

    const handleImgError = () => {
        // setImgSrc(fallbackCoinImg)
    }

    return (
        <div className='m-2'>
            <Card className='event-information-card event-information-card mb-5 mt-2' loading={loading}>
                <div className='mt-1'>
                    {/* <img className='card-image mb-3' alt='Token Logo' src={CoinIcon} /> */}
                    <h4 className='event-information-card-title'>Event Information</h4>
                    <div className='mt-1 mb-4'>
                        <div className='horizontal-line'></div>
                    </div>
                </div>
                <div>
                    <div className='event-information-content'>
                        <p className='ci-text'>Type</p>
                        <p className='ci-text2'>{data?.type}</p>
                    </div>
                </div>
                <div>
                    <div className='event-information-content'>
                        <p className='ci-text'>Location</p>
                        <p className='ci-text2'>{data?.location_name}</p>
                    </div>
                </div>
                <div>
                    <div className='event-information-content'>
                        <p className='ci-text'>Venue</p>
                        <p className='ci-text2'>{data?.venue} </p>
                    </div>
                </div>
                <div>
                    <div className='event-information-content'>
                        <p className='ci-text'>Date</p>
                        <p className='ci-text2'>{moment(data?.start_datetime).format('MMMM DD, YYYY')}</p>
                    </div>
                </div>
                <div>
                    <div className='event-information-content'>
                        <p className='ci-text'>Time</p>
                        <p className='ci-text2'>{moment(data?.start_datetime).format('hh:mm A')}</p>
                    </div>
                </div>
                <div>
                    <div className='event-information-content'>
                        <p className='ci-text'>Duration</p>
                        <p className='ci-text2'>
                            {moment.duration(moment(data?.end_datetime).diff(moment(data?.start_datetime))).humanize()}
                        </p>
                    </div>
                </div>
                <div>
                    <div className='event-information-content'>
                        <p className='ci-text'>Starting Price</p>
                        <p className='ci-text2'>
                            {typeof data?.price === 'string' ? parseFloat(data.price).toFixed(2) : 'N/A'}
                            {/* {typeof(data?.price)} */}
                        </p>
                    </div>
                </div>
                <div>
                    <div className='event-information-content'>
                        <p className='ci-text'>Registration</p>
                        <Tag className={ data?.registration === parseInt(0) ? 'ci-presale-status-tag' : 'ci-status-tag' }>{data?.registration === parseInt(0) ? 'closed' : 'open'}</Tag>
                    </div>
                </div>
            </Card>
        </div>
        
    )
}

export default EventInformationCard