import React from 'react'
import { Tag } from 'antd'

function Status(props) {

    const { status } = props

    const getStatusTag = () => {
        switch (status) {
            case "pending":
                return (<Tag className='text-dark fw-bold' color={'#33C2FF'}>{status?.toUpperCase()}</Tag>)
            case "in-review":
                return (<Tag className='text-dark fw-bold' color={'#faad14'}>{status?.toUpperCase()}</Tag>)
            case "rejected":
                return (<Tag className='fw-bold' color={'#f5222d'}>{status?.toUpperCase()}</Tag>)
            case "active":
                return (<Tag className='fw-bold' color={'#52c41a'}>{status?.toUpperCase()}</Tag>)
        }
    }

    return (
        <div>
            {getStatusTag()}
        </div>
    )
}

export default Status