import React, { useState, useEffect } from 'react'
import { FireFilled, LoadingOutlined } from '@ant-design/icons'
import { Button, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import './VoteButton.css'
import api from '../../Services/Api'

function VoteButton(props) {
    const { voteCount, isVoted, dispatchEvent, coinID } = props;
    const [voteButtonClass, setVoteButtonClass] = useState('vote-button')
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const clientIP = localStorage.getItem('rfc_client_ip') ? localStorage.getItem('rfc_client_ip') : null

    useEffect(() => {
        if(isVoted) {
            setVoteButtonClass('vote-button-voted')
        } else {
            setVoteButtonClass('vote-button')
        }
    }, [isVoted])

    // if not voted, trigger vote fuction and then reload the table
    const handleVote = async () => {
        if(!isVoted && clientIP) {
            setIsLoading(true)

            try {
                await api.post(`coin/${coinID}/vote`, { client_ip: clientIP })
                    .then((response) => {
                        if(response) {
                            dispatch(dispatchEvent) // reload the table
                        }
                })
            } catch (error) {
                console.log('ERROR on casting vote action', error)
            }

            setIsLoading(false)
        }
    }

    const antIcon = (
        <LoadingOutlined
          style={{
            fontSize: 22,
          }}
          spin
        />
      );
      

    return (
        <div>
            <Button className={`${voteButtonClass}`} onClick={() => handleVote()}>
                {
                    !isLoading ? (
                    <div className='d-flex justify-content-around'>
                        <div className='fire-icon' >
                            <FireFilled style={{ fontSize: '14px', color: '#FFA31A' }} />
                        </div>
                        <div>{voteCount}</div>
                    </div>
                    ) : (
                        <div className='text-center'>
                            <Spin size="small" indicator={antIcon} />
                        </div>
                    )
                }
            </Button>
        </div>
    )
}

export default VoteButton