import React, { useEffect, useState } from 'react'
import './AuditTable.css'
import { Table, notification, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUpcomingGems } from '../../Redux/UpcomingGemsSlice'
import Badges from '../Badges/Badges'
import MarketCap from '../MarketCap/MarketCap'
import RiskLevel from '../RiskLevel/RiskLevel'
import TokenName from '../TokenName/TokenName'
import moment from 'moment'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import VoteButton from '../VoteButton/VoteButton'
import Chain from '../Chain/Chain'

function AllTokenListTable() {

    // table data related variables
    const { loading, data, error } = useSelector((state) => state.upcomingGems)
    const dispatch = useDispatch()

    // pagination related data
    const [pageNumber, setPageNumber] = useState(1)

    // antd notifications without duplicating
    const notificatioKey = 'error';

    // fetch data from API
    useEffect(() => {
        dispatch(fetchUpcomingGems(pageNumber))
    }, [pageNumber])

    const handleBack = () => {
        if(pageNumber > 1) {
            let _pageNumber = pageNumber
            setPageNumber(_pageNumber - 1)
        }
    }

    const handleNext = () => {
        let _pageNumber = pageNumber
        setPageNumber(_pageNumber + 1)
    }

    // error nofitications
    useEffect(() => {
        if (error) {
            notification['error']({
                key: notificatioKey,
                message: 'Oops!',
                description: error
            });
        }
    }, [error])

    // columns related data
    const columns = [
        {
            key: "id",
            title: 'No',
            dataIndex: "id",
            render: (_, record, index) => ((pageNumber - 1) * 10 + (index + 1)),
        },
        {
            key: "name",
            title: 'Name',
            dataIndex: "name",
            render: (_, record) => (
                <TokenName id={record.id} tokenName={record.name} tokenSymbol={record.symbol} logoLink={record.logo_link} coingecko_link={record.coingecko} coinmarketcap_link={record.coinmarketcap} />
            ),
        },
        {
            key: "chain",
            title: "Chain",
            dataIndex: "chain",
            render: (_, record, index) => (
                <Chain tokenData={record} />
            ),
        },
        {
            key: "market_cap",
            title: 'Market Cap',
            render: (_, record) => <span className='marketcap-text'><MarketCap address={record.bsc_contract_address ? record.bsc_contract_address : record.ethereum_contract_address}  chain={record.bsc_contract_address ? 'bsc' : 'eth'} /></span>,
        },
        {
            key: "released_on",
            title: 'Released',
            render: (_, record) => <span>{moment(record.release_date).fromNow()}</span>,
        },
        {
            key: "risk_level",
            title: 'Risk Level',
            render: (_, record) => <RiskLevel riskLevel={record.risk_level} />,
        },
        {
            key: "Badges",
            title: 'Badges',
            dataIndex: "badges",
            render: (_, record) => <div>
                <Badges />
            </div>
        },
        {
            key: "votes",
            title: 'Votes',
            dataIndex: "vote_count",
            render: (_, record) => <div>
                <VoteButton voteCount={record.vote_count} />
            </div>
        }
    ];

    return (
        <div className='table'>
            <div className='table-responsive'>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                    size='small'
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                />
                
                {/* <div className='d-flex justify-content-center'> 
                    <Button 
                        type="default"
                        onClick={() => handleBack()}
                        style={{marginRight: '2px'}}
                        disabled={pageNumber === 1}
                    >
                        <div style={{marginTop: '-7px'}}>
                            <LeftOutlined />
                        </div>
                    </Button>


                    <Button 
                        type="default"
                        onClick={() => handleNext()}
                        style={{marginLeft: '2px'}}
                    >
                        <div style={{marginTop: '-7px'}}>
                            <RightOutlined />
                        </div>
                    </Button>
                </div> */}
            </div>
        </div>
    )
}

export default AllTokenListTable