import React from 'react'

function AllTokensSection() {
  return (
    <div>
      <h3 className="text-uppercase">All Coins</h3>
      {/* <p>All tokens list sub heading.</p> */}
      {/* <div className='divider'></div> */}
    </div>
  )
}

export default AllTokensSection