import { configureStore } from "@reduxjs/toolkit";
import sideMenuReducer from "./SideMenu";
import PromotedTokensReducer from "./PromotedTokensSlice";
import AuditedTokensReducer from "./AuditedTokensSlice";
import AllTimeBestTokensReducer from "./AllTimeBestTokensSlice";
import Brc20TokensReducer, { brc20TokensSlice } from "./Brc20TokensSlice";
import TodayBestTokensReducer from "./TodayBestTokensSlice";
import MostTrustedTokenSlice from "./MostTrustedTokenSlice";
import AllTokenListSlice from "./AllTokenListSlice";
import UpcomingGemsSlice from "./UpcomingGemsSlice";
import DoxxedTokensSlice from "./DoxxedTokensSlice";
import TokenDataSlice from "./TokenDataSlice";
import AuthSlice from "./AuthSlice";
import AddCoinSlice from "./AddCoinSlice";
import UserTokensSlice from "./UserTokensSlice";
import UpcomingEventsSlice from "./UpcomingEventsSlice";
import EventDataSlice from "./EventDataSlice";
import EventCommentsDataSlice from "./EventCommentsDataSlice";
import EventVoteCount from "./GetEventVoteCount"
import pastEventsSlice from "./pastEventsSlice";
import RecentlyAddedTokenSlice from "./RecentlyAddedTokenSlice";
import Brc20TokenNewReducer from "./Brc20TokensSliceNew"; 

export const store = configureStore({
  reducer: {
    sideMenu: sideMenuReducer,
    promotedTokens: PromotedTokensReducer,
    auditedTokens: AuditedTokensReducer,
    allTimeBestTokens: AllTimeBestTokensReducer,
    todayBestTokens: TodayBestTokensReducer,
    mostTrustedToken: MostTrustedTokenSlice,
    allTokenList: AllTokenListSlice,
    upcomingGems: UpcomingGemsSlice,
    doxxedTokens: DoxxedTokensSlice,
    tokenData: TokenDataSlice,
    auth: AuthSlice,
    addCoin: AddCoinSlice,
    userCoins: UserTokensSlice,
    upComingEvents: UpcomingEventsSlice,
    eventData: EventDataSlice,
    eventComments: EventCommentsDataSlice,
    eventVoteCount: EventVoteCount,
    brc20Tokens: Brc20TokensReducer,
    pastEvents: pastEventsSlice,
    recentltAddedTokens: RecentlyAddedTokenSlice,
    brc20TokensDataNew: Brc20TokenNewReducer
  }
});
