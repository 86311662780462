import React, { useEffect } from 'react'
import { Button, Card } from 'antd'
import AuditContactComponent from '../../Components/AuditPageComponents/AuditContactComponent'
// import AuditTable from '../../Components/Tables/AuditTable'
import { Link } from 'react-router-dom'
// import AuditCard from '../../Components/AuditPageComponents/AuditCard'
import AuditTab from '../../Components/AuditPageComponents/AuditTab/AuditTab'
import AddHere from '../../Components/AddHere/AddHere'
import AuditTable from '../../Components/Tables/AuditTable'
import BannerAdd from '../../Components/AddHere/BannerAdd'

function AuditPage() {

    // const [showTable, setShowTable] = useState(false)
    // const getAuditTable = () => setShowTable(true)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className='container-lg mt-5'>
                {/* <div className='mt-5'>
                    <BannerAdd />
                </div> */}
                <div className=''>
                    <AddHere />
                </div>

                <div className='row'>
                    <h2 className='page-header-title heading-2'>Audited Coins</h2>
                </div>

                <Card className='home-tabs-card mb-5'>
                    <AuditTable />
                </Card>
                
            </div>
            {/* <div className='container-fluid d-flex flex-column justify-content-center mt-2 pt-3'>
                <div className='service-button'><h2 data-aos="fade-up" data-aos-delay="50" className='heading-2'>How our audit process work?</h2></div>
                <p data-aos="fade-up" data-aos-delay="50" className='page-header-description description pt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam volutpat, turpis eu venenatis vehicula, enim mi auctor diam, vel luctus magna libero quis mi. </p>
                <div className='service-button pt-5 pb-5 mb-5'>
                    <Link to={'/audit-process'}><Button data-aos="fade-up" data-aos-delay="50" className="animate__animated animate__fadeIn animate__delay-1s" type="primary" align='center' size='large'>Learn More</Button></Link>
                </div>
            </div> */}
            <AuditContactComponent />
        </>
    )
}

export default AuditPage