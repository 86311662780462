import React, { useState } from 'react'
import { CalendarOutlined, DollarCircleOutlined, RocketOutlined, SearchOutlined, SnippetsOutlined, TrophyOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Form, Input, List, Steps, Switch } from 'antd'
import { Col, Row } from 'reactstrap'
import './CreateAidropComponent.css'
import AirdropDepositTokens from './AirdropDepositTokens'
import AirdropFinaliseAirdrop from './FinaliseAirdrop'

function AirdropTokenInformation() {

    const [isTwitter, setIsTwitter] = useState(false)
    const [isTelegram, setIsTelegram] = useState(false)
    const [isConnectWallet, setIsConnectWallet] = useState(false)

    const onChangeIsTwitter = (value) => {
        if (value) {
            setIsTwitter(true)
        } else {
            setIsTwitter(false)
        }
    }

    const onChangeIsTelegram = (value) => {
        if (value) {
            setIsTelegram(true)
        } else {
            setIsTelegram(false)
        }
    }

    const onChangeWalletConnect = (value) => {
        if (value) {
            setIsConnectWallet(true)
        } else {
            setIsConnectWallet(false)
        }
    }



    return (
        <>
            <Card className='home-tabs-card'>
                <Form
                    className='mt-3'
                    name="token_information"
                    // onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <h3>Token Information</h3>

                    <Row className='mt-3'>
                        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                            <span className='small'>Search Your Coin</span>
                            <Form.Item
                                name="search_coin_name"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'The Token Name field is required!',
                            //     },
                            // ]}
                            >
                                <Input
                                    prefix={<SearchOutlined />}
                                    size="large"
                                    placeholder='Search coin, symbol...'
                                />
                                {/* <Search
                    placeholder="Search coin, symbol..."
                    onSearch={value => console.log(value)}
                    size="large"
                /> */}
                            </Form.Item>
                        </Col>

                        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                            <span className='small'>Start of the Airdrop</span>
                            <Form.Item
                                name="airdrop_start_date"
                                // className='ant-input-affix-wrapper'
                                rules={[
                                    {
                                        required: true,
                                        message: 'The start of the airdrop field is required!',
                                    },
                                ]}
                            >
                                <DatePicker

                                    size="large"
                                    className='w-100 datepicker-size ant-input-affix-wrapper'
                                // onChange={onChangePresaleDate}
                                />
                            </Form.Item>
                        </Col>

                        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                            <span className='small'>Total Airdrop Amount</span>
                            <Form.Item
                                name="total_airdrop_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Airdrop amount field is required!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<DollarCircleOutlined />}
                                    size="large"
                                    placeholder='100000'
                                />
                            </Form.Item>
                        </Col>

                        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                            <span className='small'>Number of days (Min 5, Max 20)</span>
                            <Form.Item
                                name="actual_price"
                                rules={[
                                    {
                                        required: true,
                                        message: 'The number of days field is required!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<CalendarOutlined />}
                                    size="large"
                                    placeholder="6"
                                    min={5}
                                    type="number"
                                    onWheel={() => document.activeElement.blur()}
                                />
                            </Form.Item>
                        </Col>

                        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                            <span className='small'>Number of Winners</span>
                            <Form.Item
                                name="no_of_winners"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Number of Winners field is required!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<TrophyOutlined />}
                                    size="large"
                                    placeholder='100000'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                            <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                <Form.Item
                                    name="is_twitter"
                                >
                                    <span className='small'>Add Follow Twitter Step?</span>
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onChange={onChangeIsTwitter}
                                    />
                                </Form.Item>
                            </Col>
                            {
                                isTwitter &&
                                <Row>
                                    <Col sm="12" xs="12">
                                        <span className='small'>Twitter Link</span>
                                        <Form.Item
                                            name="twitter_link"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Twitter Link field is required!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                prefix={<SnippetsOutlined />}
                                                size="large"
                                                placeholder='Twitter Username'
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </Col>
                        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                            <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                <Form.Item
                                    name="is_twitter"
                                >
                                    <span className='small'>Add Follow Telegram Step? </span>
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onChange={onChangeIsTelegram}
                                    />
                                </Form.Item>
                            </Col>
                            {
                                isTelegram &&
                                <Row>
                                    <Col sm="12" xs="12">
                                        <span className='small'>Telegram Link</span>
                                        <Form.Item
                                            name="telegram_link"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Telegram Link field is required!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                prefix={<SnippetsOutlined />}
                                                size="large"
                                                placeholder='Telegram Username'
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                    <Form.Item>
                        <div className='text-center mt-4'>
                            <Button className='connect-metamask' onClick={onChangeWalletConnect} >
                                Connect to create Airdrop
                            </Button>
                        </div>
                        {isConnectWallet &&
                            <Row>
                                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                    <AirdropDepositTokens />
                                </Col>
                                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                    <AirdropFinaliseAirdrop />
                                </Col>
                            </Row>
                        }
                    </Form.Item>
                    {/* <Form.Item>
                        <div className='text-center mt-4'>
                            <Button size='large' type="primary" onClick={() => {
                                console.log('Form submitted!');
                                setIsConnectWallet(false);
                            }}>
                                Submit
                            </Button>
                        </div>
                    </Form.Item> */}
                </Form>
            </Card>
        </>
    )
}

export default AirdropTokenInformation