import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

// initialize client IP from local storage
const clientIP = localStorage.getItem('rfc_client_ip')
    ? localStorage.getItem('rfc_client_ip')
    : null

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch data from api
export const fetchTodayBestTokens = createAsyncThunk('fetchTodayBestTokens', async () => {
    return api
        .get(`coins/today-best?per_page=10&direction=DESC&sort_key=vote_count&ip_address=${clientIP}&page=1`)
        .then((response) => response.data.payload)
})

export const todayBestTokensSlice = createSlice({
    name: "todayBestTokens",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchTodayBestTokens.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchTodayBestTokens.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
        })
        builder.addCase(fetchTodayBestTokens.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
    }
});

export default todayBestTokensSlice.reducer