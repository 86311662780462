import { Card, Tabs } from 'antd'
import React, { useState } from 'react'
import SearchBar from '../AuditPageComponents/SearchBar';
import AllTimeBestTable from '../Tables/AllTimeBestTable';
import TodayBestTable from '../Tables/TodayBestTable'
import AuditTable from '../Tables/AuditTable'
import UpcomingGemsTable from '../Tables/UpcomingGemsTable'
import DoxxedTokensTable from '../Tables/DoxxedTokensTable'
import { StarFilled, FireFilled, AuditOutlined } from '@ant-design/icons'


function AllTokenListTab() {

    const deviceWidth = window.innerWidth;

    const [searchTerm, setSearchTerm] = useState('')
    const [activeKey, setActiveKey] = useState('tab1')

    const operations = <SearchBar setSearchTerm={setSearchTerm} />;

    const changeTab = activeKey => {
        setActiveKey(activeKey);
    };

    return (
        <>
            <Card className='home-tabs-card mb-5'>
                <Tabs tabBarExtraContent={activeKey === 'tab1' && deviceWidth >= 768 && operations} type="card" defaultActiveKey='tab1' onChange={changeTab}>
                    <Tabs.TabPane tab={<div className='d-flex'><div style={{marginTop: '-3px'}}><StarFilled /></div><span>All Time Best</span></div>} key='tab1'>
                        <p>Won't get visibility unless voted, so make sure your community knows about it.</p>
                        <AllTimeBestTable searchTerm={searchTerm} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<div className='d-flex'><div style={{marginTop: '-3px'}}><FireFilled /></div><span>Today Best</span></div>} key='tab2'>
                        <p>Today best performing coins.</p>
                        <TodayBestTable />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<div className='d-flex'><div style={{marginTop: '-3px'}}><AuditOutlined /></div><span>Audited Coins</span></div>} key='tab3'>
                        <p>We give 95% assurance to the public that those audited coins are rug pull-free and scams will not likely happen. We will keep tracking the coins daily and make sure no suspicious activity will occur.</p>
                        <AuditTable />
                    </Tabs.TabPane>
                </Tabs>
            </Card>
        </>
    )
}

export default AllTokenListTab