import React from 'react'
import AllTimeBestTable from '../../../Tables/AllTimeBestTable'

function AllTimeBestTab() {
    return (
        <div>
            {/* <h3 className='mt-3'>All Time Best</h3> */}
            {/* <p className='mb-2'>We give 95% assurance to the public that those audited coins are rug pull-free and scams will not likely happen. We will keep tracking the coins daily and make <br />
                sure no suspicious activity will occur.</p> */}
            <AllTimeBestTable />
        </div>
    )
}

export default AllTimeBestTab