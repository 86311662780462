import React from 'react'

function AddHere() {
  return (
    <div className='text-center mb-4'>
        <iframe
            data-aa="1717027"
            src="//ad.a-ads.com/1717027?size=970x90"
            className='iframe-styles'
        ></iframe>
    </div>
  )
}

export default AddHere