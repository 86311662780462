import { Button, Card, notification } from "antd";
import React, { useState, useEffect } from "react";
import "./EventDescriptionCard.css";
import StarIcon from "../../../images/star.svg";
import {
  FireFilled,
  FileProtectOutlined,
  RedditCircleFilled,
  LeftSquareTwoTone,
} from "@ant-design/icons";
import Badges from "../../Badges/Badges";
import { Row, Col } from "reactstrap";
import Web from "../../../images/sm-icon/web.svg";
import Telegram from "../../../images/sm-icon/telegram.svg";
import Instagram from "../../../images/instagram-icon.svg";
import Twitter from "../../../images/sm-icon/twitter.svg";
import Discord from "../../../images/sm-icon/discord.svg";
import Pancakeswap from "../../../images/PancakeSwap-Logo.png";
import infoIcon from "../../../images/info.svg";
import fallbackCoinImg from "../../../images/no-img.png";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTokenData } from "../../../Redux/TokenDataSlice";
import { fetchCryptoEventData } from "../../../Redux/EventDataSlice";
import { fetchUpcomingEvents } from "../../../Redux/UpcomingEventsSlice";
import { fetchEventVoteCount } from "../../../Redux/GetEventVoteCount";
import api from "../../../Services/Api";
import Address from "../../Address/Address";
import { Link } from "react-router-dom";
import moment from "moment";

function EventDescriptionCard() {
  const { id } = useParams();
  const { loading, data, error } = useSelector((state) => state.eventData);
  //console.log('data>>>', data);
  const [imgSrc, setImgSrc] = useState(fallbackCoinImg);
  const dispatch = useDispatch();
  const [voteButtonClass, setVoteButtonClass] = useState(
    "vote-button-info-card"
  );
  const [favButtonClass, setFavButtonClass] = useState("vote-button-info-card");
  const clientIP = localStorage.getItem("rfc_client_ip")
    ? localStorage.getItem("rfc_client_ip")
    : null;
  const [isLoading, setIsLoading] = useState(false);
  // antd notifications without duplicating
  const notificatioKey = "error";
  // const [isAudited, setIsAudited] = useState(false)
  // const [isHodl, setIsHodl] = useState(false)
  // const [isHodlDescription, setIsHodlDescription] = useState(false)
  // const [auditReportUrl, setAuditReportUrl] = useState('')
  // const { voteCountLoading, voteCountData, voteCountError } = useSelector((state) => state.eventVoteCount);
  const [favEvents, setFavEvents] = useState([]);
  const { userProfile } = useSelector((state) => state.auth);
  const [voteCount, setVoteCount] = useState([]);

  //vote count
  useEffect(() => {
    getVoteCount(id);
  }, [id]);

  // get vote count
  const getVoteCount = (id) => {
    api
      .get(`crypto-events/event/${id}/vote-count`)
      .then((response) => {
        // console.log('vote', response)
        setVoteCount(response.data.payload);
      })
      .catch((error) => console.log(error));
  };

  // if not voted, trigger vote fuction and then reload the table
  const handleVote = (id, userId) => {
    api
      .post("/crypto-events/vote", {
        user_id: userId,
        event_id: id,
      })
      .then((response) => {
        // console.log(response)
        getVoteCount(id);
      });
  };

  // error nofitications
  useEffect(() => {
    if (error) {
      notification["error"]({
        key: notificatioKey,
        message: "Oops!",
        description: error,
      });
    }
  }, [error]);

  useEffect(() => {
    if (data?.is_voted) {
      setVoteButtonClass("vote-button-info-card-voted");
    } else {
      setVoteButtonClass("vote-button-info-card");
    }
  }, [data?.is_voted]);

  useEffect(() => {
    setImgSrc(data?.image_url);
  }, [data?.image_url]);

  const handleImgError = () => {
    // setImgSrc(fallbackCoinImg)
  };

  //fav button clicked
  const handleFav = () => {
    const event_id = data?.id;

    setFavEvents([...favEvents, event_id]);
    localStorage.setItem("favEvents", JSON.stringify([...favEvents, event_id]));
  };

  useEffect(() => {
    const storedFavEvents = JSON.parse(localStorage.getItem("favEvents"));
    if (storedFavEvents) {
      setFavEvents(storedFavEvents);
    }
  }, [id]);

  return (
    <div className="m-2">
      <Card className="event-description-card mb-5" loading={loading}>
        <div className="mb-2">
          <Row>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <div className="d-flex">
                <img
                  src={imgSrc}
                  alt="CoinIcon"
                  className="card-icon-tc"
                  onError={handleImgError}
                />
                <div className="mt-3">
                  <h5>{data?.name}</h5>
                  <p className="subtext1">
                    {data?.location_name} -{" "}
                    {moment(data?.start_datetime).format("MMMM DD, YYYY")}
                  </p>
                </div>
              </div>
            </Col>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <div className="text-md-end">
                {/* <Address tokenData={data} /> */}
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-3">
          <Row>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12" className="mt-3">
              <div className="mt-1">
                {data?.website && (
                  <a href={data?.website} target="_blank">
                    <img className="sm-icon" src={Web} alt="" />
                  </a>
                )}
                {data?.telegram && (
                  <a href={data?.telegram} target="_blank">
                    <img className="sm-icon" src={Telegram} alt="" />
                  </a>
                )}
                {data?.twitter && (
                  <a href={data?.twitter} target="_blank">
                    <img className="sm-icon" src={Twitter} alt="" />
                  </a>
                )}
                {data?.discord && (
                  <a href={data?.discord} target="_blank">
                    <img className="sm-icon" src={Discord} alt="" />
                  </a>
                )}
                {data?.instagram && (
                  <a href={data?.instagram} target="_blank">
                    <img
                      className="sm-icon"
                      src={Instagram}
                      alt=""
                      style={{ width: "25px", height: "25px" }}
                    />
                  </a>
                )}
                {data?.reddit && (
                  <a href={data?.reddit} target="_blank">
                    <RedditCircleFilled
                      style={{
                        fontSize: "22px",
                        color: "#ff4500",
                        backgroundColor: "white",
                        borderRadius: "100%",
                      }}
                    />
                  </a>
                )}
              </div>
            </Col>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <div className="d-md-flex justify-content-end">
                <Button
                  className={`d-flex mt-3 ${voteButtonClass}`}
                  style={{ marginRight: "5px" }}
                  onClick={() => handleVote(id, userProfile?.id)}
                  loading={isLoading}
                >
                  <div style={{ marginTop: "-2px", marginRight: "5px" }}>
                    <FireFilled
                      style={{ fontSize: "14px", color: "#FFA31A" }}
                    />
                  </div>
                  {voteCount}
                </Button>
                <Button
                  style={{ marginRight: "5px" }}
                  className="mt-3 add-to-watchlist-info-card"
                  onClick={() => {
                    handleFav();
                  }}
                >
                  <img src={StarIcon} alt="Star" className="star-icon" /> Add to
                  favorites
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-4">
          <div className="horizontal-line"></div>
        </div>

        <div className="mt-3">
          {data?.description && parse(data?.description)}
        </div>

        <div className="mt-5">
          <span>
            <img src={infoIcon} alt="Infomations" />
            <span> Information incorrect? Please submit an</span>
            <Link
              style={{ color: "#F49200" }}
              to="https://t.me/RugFreeCoins_Marketing"
              target="_blank"
            >
              {" "}
              Update Request!
            </Link>
          </span>
        </div>
      </Card>
    </div>
  );
}

export default EventDescriptionCard;
