import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
// import CoinGecko from '../../images/coingecko.png'
// import CoinMarketCap from '../../images/coinmarketcap.png'
// import CoinMarketCap from '../../images/coinmarketcap-logo.png'
import fallbackCoinImg from '../../images/no-img.png'

function AirdropName(props) {
    const { id, logoLink, tokenName, tokenSymbol } = props

    const [imgSrc, setImgSrc] = useState(fallbackCoinImg)

    useEffect(() => {
        setImgSrc(logoLink)
    }, [logoLink])

    const handleImgError = () => {
        setImgSrc(fallbackCoinImg)
    }

    return (
        <div className='d-flex'>
            <Link to={id && `/coin-details/${id}`} className="my-auto">
                <div className='d-flex' style={{ minWidth: '100px', marginLeft: '0px' }}>
                    <div className='my-auto'>
                        <img src={imgSrc} alt="logo" className='token-logo' onError={handleImgError} />
                    </div>

                    <div style={{ marginLeft: '10px' }} className="my-auto">
                        <span>{tokenName}</span>
                        <br />
                        <span className='text-muted small'>{tokenSymbol}</span>
                    </div>
                </div>
            </Link>

            {/* <div className='my-auto'>
                {coingecko_link && 
                    <>
                        <a href={coingecko_link} target="_blank">
                            <img src={CoinGecko} className='p-1 token-logo' />
                        </a>
                        <br />
                    </>
                }
                { coinmarketcap_link && 
                    <a href={coinmarketcap_link} target="_blank">
                        <img src={CoinMarketCap} className='p-1 token-logo' />
                    </a>
                }
            </div> */}
        </div>
    )
}

export default AirdropName