import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserTokens } from '../../Redux/UserTokensSlice'
import { Table, notification } from 'antd'
import TokenName from '../TokenName/TokenName'
import Chain from '../Chain/Chain'
import moment from 'moment'
import Status from '../Status/Status'

function UserCoinsTable() {

    // table data related variables
    const { loading, data, error } = useSelector((state) => state.userCoins)
    // auth related variables
    const { userProfile } = useSelector((state) => state.auth) 
    const dispatch = useDispatch()

    useEffect(() => {
        if (userProfile?.id) {
            dispatch(fetchUserTokens(userProfile?.id))
        }
    }, [userProfile])

    // error nofitications
    useEffect(() => {
        if (error) {
            notification['error']({
                key: 'error',
                message: 'Oops!',
                description: error
            });
        }
    }, [error])

    const columns = [
        {
            key: "id",
            title: 'No',
            dataIndex: "id",
            render: (_, record, index) => (
                <span className='row-no'>{index + 1}</span>
            ),
        },
        {
            key: "name",
            title: 'Name',
            fixed: 'left',
            dataIndex: "name",
            render: (_, record) => (
                <TokenName id={null} tokenName={record.name} tokenSymbol={record.symbol} logoLink={record.logo_link} coingecko_link={record.coingecko} coinmarketcap_link={record.coinmarketcap} />
            ),
        },
        {
            key: "chain",
            title: "Chain",
            dataIndex: "chain",
            render: (_, record, index) => (
                <Chain tokenData={record} />
            ),
        },
        {
            key: "market_cap",
            title: 'Market Cap',
            render: (_, record) => <span className='marketcap-text'>$ {record?.actual_market_cap}</span>,
        },
        {
            key: "released_on",
            title: 'Released',
            render: (_, record) => <span className='released-data'>{moment(record.release_date).fromNow()}</span>,
        },
        {
            key: "actual_price",
            title: 'Price',
            render: (_, record) => <span className='marketcap-text'>$ {record?.actual_price}</span>,
        },
        {
            key: "status",
            title: 'Status',
            render: (_, record) => <Status status={record?.status} />,
        },
    ]



    return (
        <div className='table'>
            <div className='table-responsive'>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                    size='small'
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                />
            </div>
        </div>
    )
}

export default UserCoinsTable