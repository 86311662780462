import React, { useEffect } from 'react'
import { notification, Skeleton, Avatar } from 'antd'
import TokenListItem from '../../TokenListItem/TokenListItem';
import { useDispatch, useSelector } from 'react-redux'
import { fetchTodayBestTokens } from '../../../Redux/TodayBestTokensSlice'

function TrendingTokens() {

  const { loading, data, error } = useSelector((state) => state.todayBestTokens)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTodayBestTokens())
  }, [dispatch])

  // antd notifications without duplicating
  const notificatioKey = 'error';

  // error nofitications
  useEffect(() => {
    if (error) {
      notification['error']({
        key: notificatioKey,
        message: 'Oops!',
        description: error
      });
    }
  }, [error])


  return (
    <div>
      {loading ?
        <div>
          <Skeleton loading={loading} avatar active paragraph={{ rows: 0, }} />
          <Skeleton loading={loading} avatar active paragraph={{ rows: 0, }} />
          <Skeleton loading={loading} avatar active paragraph={{ rows: 0, }} />
        </div> :
        data.map((item, index) => (
          index < 3 ? <TokenListItem key={index} itemData={item} /> : ''
        ))
      }
    </div>
  )
}

export default TrendingTokens