import React from 'react'
import { Avatar } from 'antd'
import CloseIcon from '../../images/close-icon.png'

function AuditContactComponent() {
    return (
        <>
            <div className='container-fluid stats-section d-flex flex-column justify-content-center mt-2 pt-5'>
                <div className='service-button'>
                    <h2 data-aos="fade-up" data-aos-delay="50" className='heading-2'>Want to perform an audit?</h2>
                </div>
                <div className='service-button pt-2'>
                    <p data-aos="fade-up" data-aos-delay="50" className='email-text'>Send us an email:</p>
                </div>
                <div className='service-button pt=1'>
                    <h5 data-aos="fade-up" data-aos-delay="50" className="text-color email-style" align='center' > contact@rugfreecoins.com </h5>
                </div>
                <div className='service-button pt-5 pb-5'>
                    <h6 className='stats-sections-red red-note-text p-2'><img src={CloseIcon} style={{width: '18px', marginTop: '-2px'}} /> Do never pay anyone for a promotion on our platform, unless you have received a confirmation email from this address.</h6>
                </div>
            </div>
        </>
    )
}

export default AuditContactComponent