import React, { useEffect, useState } from 'react'
import { Card, Tabs } from 'antd'
import { FireFilled, RocketFilled, StarFilled } from '@ant-design/icons'
import CoinDetailsOverview from './CoinDetailsOverview'
import CoinDetailsAirdrop from '../AirdropComponents/CoinDetailsAirdrop'
import AddHere from '../../Components/AddHere/AddHere'

function CoinDetails() {

    const [activeKey, setActiveKey] = useState('tab1')

    const changeTab = activeKey => {
        setActiveKey(activeKey);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <>
            <div className='mb-5'>
                <div className='page-header'>
                <AddHere />
                    <div className='container pt-5'>
                        {/* <Card className='home-tabs-card mb-5'> */}
                        <Tabs tabBarExtraContent={activeKey === 'tab1'} type="card" defaultActiveKey='tab1' onChange={changeTab}>
                            <Tabs.TabPane tab={<div className='d-flex'><div style={{ marginTop: '-3px' }}><StarFilled /></div><span>Overview</span></div>} key='tab1'>
                                <CoinDetailsOverview />
                            </Tabs.TabPane>
                            {/* <Tabs.TabPane tab={<div className='d-flex'><div style={{ marginTop: '-3px' }}><FireFilled /></div><span>Airdrop</span></div>} key='tab2'>
                                <CoinDetailsAirdrop />
                            </Tabs.TabPane> */}
                        </Tabs>
                        {/* </Card> */}
                    </div>
                </div>
            </div>
            {/* <>
                <div className='mb-5'>
                    <div className='container pt-5'>
                        <Tabs>
                            <Tabs.TabPane tab={<div className='d-flex'><div style={{ marginTop: '-3px', marginLeft: '10px' }}>
                                <StarFilled />
                            </div><span style={{ width: '100px' }}>Overview</span></div>}
                                key='tab1'>
                                <CoinDetailsOverview />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<div className='d-flex'><div style={{ marginTop: '-3px', marginLeft: '10px' }}>
                                <FireFilled />
                            </div><span style={{ width: '100px' }}>Airdrop</span></div>} key='tab2'>
                                <CoinDetailsOverview />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </> */}
        </>
    )
}

export default CoinDetails