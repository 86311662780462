import { Card, Form, Button, Input, Divider, Checkbox, notification } from 'antd'
import React, { useEffect } from 'react'
import RugFreeCoinsLogo from '../../images/rugfreecoins.png'
import { Link, useNavigate } from 'react-router-dom'
import './auth.css'
import { useDispatch, useSelector } from 'react-redux'
import { registerUser, reset } from '../../Redux/AuthSlice'

function Register() {

    // auth related variables
    const { loading, error, token, success} = useSelector((state) => state.auth) 
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // error nofitications
    useEffect(() => {
        if (error) {
            notification['error']({
                key: 'error',
                message: 'Oops!',
                description: error
            });

            dispatch(reset()) // reset error message 
        }
    }, [error])

    useEffect(() => {
        if(success) {
            notification['success']({
                key: 'success',
                message: 'Success',
                description: 'Successfully logged in!'
            });

            dispatch(reset()) // reset success message
            navigate('/profile') // navigate to home page
        }
    }, [token])

    const onFinish = (values) => {
        //creat the form request
        let formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("password", values.password);
        dispatch(registerUser(formData))
    };

    return (
        <div>
            <div className='container col-lg-4 col-md-5 col-xs-12 mx-auto center'>
                <Card className='home-tabs-card'>
                    <div className='text-center mb-4'>
                        <img src={RugFreeCoinsLogo} alt="rugfreecoins-logo" className='rugfree-logo' />
                    </div>

                    <div className='mb-4'>
                        <h5>Adventure starts here 🚀</h5>
                        <span className='text-muted'>Make your crypto hunt easy and fun</span>
                    </div>

                    <Form
                        name="register"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <span className='small'>Name</span>
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'The Name field is required!',
                                },
                            ]}
                        >
                            <Input 
                                placeholder='John Doe'
                                size="large" 
                            />
                        </Form.Item>

                        <span className='small'>Email</span>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The Email field must be a valid email!'
                                },
                                {
                                    required: true,
                                    message: 'The Email field is required!',
                                },
                            ]}
                        >
                            <Input 
                                placeholder='john@example.com'
                                size="large" 
                            />
                        </Form.Item>
                        
                        <span className='small'>Password</span>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                required: true,
                                message: 'The Password field is required!',
                                },
                            ]}
                        >
                            <Input.Password
                                placeholder='Password'
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item
                            name="agree"
                            valuePropName="checked"
                            >
                            <Checkbox>I agree to <Link to="#" className='text-active'>privacy policy & terms</Link> & receive marketing newsletters</Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="large" className='col-12' loading={loading}>
                                Sign up
                            </Button>
                        </Form.Item>
                    </Form>

                    <Divider>
                        <span className='small fw-normal'>
                            or
                        </span>
                    </Divider>

                    <div className='text-center'>
                        <p>Already have an account? <Link to="/login" className='text-active'> Sign in instead</Link></p>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default Register