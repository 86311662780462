import { Card, Tabs, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { Col, Row } from 'reactstrap'
import fallbackCoinImg from '../../images/no-img.png'
import '../../Pages/Brc20/Brc20.css'
import CoinDetailsWidget from './CoinDetailsWidget'
import NumberFormat from 'react-number-format';
import { StarFilled, RedditCircleFilled } from '@ant-design/icons'
import Brc20ListedTable from '../../Components/Tables/Brc20ListedTable'
import Brc20PriceChart from '../../Components/Tables/Brc20PriceChart'
import api from '../../Services/Api';
import RiskLevel from "../../Components/RiskLevel/RiskLevel";
import Web from "../../images/sm-icon/web.svg";
import Telegram from "../../images/sm-icon/telegram.svg";
import Twitter from "../../images/sm-icon/twitter.svg";
import Discord from "../../images/sm-icon/discord.svg";
import parse from "html-react-parser";

function Brc20Details() {

    const { id } = useParams();

    const [coinData, setTableData] = useState()
    const [coinDataLoading, setCoinDataLoading] = useState(false)
    const [imgSrc, setImgSrc] = useState(fallbackCoinImg)
    const [activeKey, setActiveKey] = useState('tab1')

    const [coinSocialDetails, setCoinSocialDetails] = useState([])
    const [coinDetailLoading, setCoinDetailLoading] = useState(false)

    const changeTab = activeKey => {
        setActiveKey(activeKey);
    };


    useEffect(() => {
        fetchBrc20TokenData()
        // const interval = setInterval(() => {
        //     fetchBrc20TokenData()
        // }, 60000) // fetch data every 5 seconds
        // return () => clearInterval(interval)
        // fetchBrc20TokenData()
    }, [])

    useEffect(() => {
        fetchCoinDetails()
    }, [id])

    const fetchCoinDetails = async () => {
        setCoinDetailLoading(true)
        try {
            api.get(`coins/brc20/token/get-by-in/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    setCoinSocialDetails(response.data.payload)
                    setCoinDetailLoading(false)
                }
            })
        } catch (error) {
            setCoinDetailLoading(false)
        }
    }

    const fetchBrc20TokenData = async () => {
        setCoinDataLoading(true)
        try {
          const response = await axios.get(
            `https://api.ordspace.org/brc-20/one?number=${id}`
          );

          console.log('response', response.data.data);
          setTableData(response.data.data);
          setCoinDataLoading(false)
        } catch (error) {
          console.error(error);
          setCoinDataLoading(false)
        }
      };

      useEffect(() => {
        setImgSrc(`https://ordspace.org/brc20-icon/${coinData?.ticker}.png`)
    }, [coinData])

    const handleImgError = () => {
        setImgSrc(fallbackCoinImg)
    }

    return (
        <div>
            <div className='container-lg'>
            {/* <Card className="coin-description-card mb-5" loading={coinDataLoading}> */}
                {
                    coinDataLoading ? (
                        <div className="d-flex justify-content-center page-header">
                            <Spin size='small' />
                        </div>
                    ) : (
                        <div className='page-header'>
                            <div>
                                <Row>
                                    <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
                                        <div className="d-flex">
                                            <img
                                            src={imgSrc}
                                            alt="CoinIcon"
                                            className="card-icon-tc"
                                            onError={handleImgError}
                                            />
                                            <div className="mt-3">
                                                <div className=" d-flex">
                                                    <h5>{coinData?.ticker}</h5>
                                                    <div className='mx-3 mt-1'>
                                                        <RiskLevel riskLevel={coinSocialDetails?.risk_level} />
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <span>Deploy Block Time: &nbsp;</span>
                                                    <span className="letter-blue"> Mar 8, 2023</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                        <div className="mt-5">
                                            {coinSocialDetails?.website && (
                                            <a href={coinSocialDetails?.website} target="_blank" rel="noreferrer">
                                                <img className="sm-icon" src={Web} alt="" />
                                            </a>
                                            )}
                                            {coinSocialDetails?.telegram_link && (
                                            <a
                                                href={coinSocialDetails?.telegram_link}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img className="sm-icon" src={Telegram} alt="" />
                                            </a>
                                            )}
                                            {coinSocialDetails?.twitter_link && (
                                            <a href={coinSocialDetails?.twitter_link} target="_blank" rel="noreferrer">
                                                <img className="sm-icon" src={Twitter} alt="" />
                                            </a>
                                            )}
                                            {coinSocialDetails?.discord_link && (
                                            <a href={coinSocialDetails?.discord_link} target="_blank" rel="noreferrer">
                                                <img className="sm-icon" src={Discord} alt="" />
                                            </a>
                                            )}
                                            {coinSocialDetails?.reddit_link && (
                                            <a href={coinSocialDetails?.reddit_link} target="_blank" rel="noreferrer">
                                                <RedditCircleFilled
                                                style={{
                                                    fontSize: "22px",
                                                    color: "#ff4500",
                                                    backgroundColor: "white",
                                                    borderRadius: "100%"
                                                }}
                                                />
                                            </a>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    coinSocialDetails?.description ? (
                                        <>
                                            <div className="mt-4">
                                                <div className="horizontal-line"></div>
                                            </div>
                                            <div className="mt-3">
                                                {coinSocialDetails?.description && parse(coinSocialDetails?.description)}
                                            </div>
                                        </>
                                    ) : (<></>)
                                }
                                
                            </div>

                            <div className="mt-4">
                                <div className="horizontal-line"></div>
                            </div>
                            <div>
                                <div style={{ display: 'flex' }} className='mt-5 detail-cards'>
                                    {/* <CoinDetailsWidget topic={coinData?.ticker} value={coinData?.ticker} /> */}
                                    <Card className="detail-card-widget">
                                        <div>
                                            <span className="coin-details-widget-topic">Price</span>
                                        </div>
                                        <div>
                                            <span className="coin-details-widget-value">
                                                <NumberFormat
                                                    displayType='text'
                                                    decimalScale={3}
                                                    value={coinData?.ticker ? coinData?.price : 0}
                                                    prefix="$"
                                                    thousandSeparator={true}
                                                />
                                            </span>
                                        </div>
                                    </Card>
                                    <Card className="mobile-side-margin detail-card-widget mobile-margin-top">
                                        <div>
                                            <span className="coin-details-widget-topic">SUPPLY</span>
                                        </div>
                                        <div>
                                            <span className="coin-details-widget-value">
                                                <NumberFormat
                                                    displayType='text'
                                                    decimalScale={3}
                                                    value={coinData?.max ? coinData?.max : 0}
                                                    thousandSeparator={true}
                                                />
                                            </span>
                                        </div>
                                    </Card>
                                    <Card className="detail-card-widget mobile-margin-top">
                                        <div>
                                            <span className="coin-details-widget-topic">Decimal</span>
                                        </div>
                                        <div>
                                            <span className="coin-details-widget-value">
                                                <NumberFormat
                                                    displayType='text'
                                                    value={coinData?.decimal ? coinData?.decimal : 0}
                                                />
                                            </span>
                                        </div>
                                    </Card>
                                    <Card className="mobile-side-margin detail-card-widget mobile-margin-top">
                                        <div>
                                            <span className="coin-details-widget-topic">Holders</span>
                                        </div>
                                        <div className="d-flex">
                                            <span className="coin-details-widget-value">
                                                <NumberFormat
                                                    displayType='text'
                                                    decimalScale={3}
                                                    value={coinData?.holdersCount ? coinData?.holdersCount : 0}
                                                    thousandSeparator={true}
                                                />
                                            </span>
                                            <div >

                                            </div>
                                        </div>
                                    </Card>
                                    <Card className="detail-card-widget mobile-margin-top">
                                        <div>
                                            <span className="coin-details-widget-topic">Limit</span>
                                        </div>
                                        <div>
                                            <span className="coin-details-widget-value">
                                                <NumberFormat
                                                    displayType='text'
                                                    decimalScale={3}
                                                    value={coinData?.limit ? coinData?.limit : 0}
                                                />
                                            </span>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                            <div className="mt-5">
                                <Card className="home-tabs-card brc20-list-section">
                                    <Tabs tabBarExtraContent={activeKey === 'tab1'} type="card" defaultActiveKey='tab1' onChange={changeTab}>
                                        <Tabs.TabPane tab={<div className='d-flex'><div style={{ marginTop: '-3px' }}><StarFilled /></div><span>LISTED</span></div>} key='tab1'>
                                            <Brc20ListedTable ticker={coinData?.ticker} id={id} />
                                        </Tabs.TabPane>
                                        {/* <Tabs.TabPane tab={<div className='d-flex'><div style={{ marginTop: '-3px' }}><FireFilled /></div><span>PRICE</span></div>} key='tab2'>
                                            <Brc20PriceChart id={id} />
                                        </Tabs.TabPane> */}
                                    </Tabs>
                                </Card>
                            </div>
                            
                        </div>
                    )
                }
                {/* </Card> */}
                
            </div>
        </div>
    )
}

export default Brc20Details;