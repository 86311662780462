import React from 'react'
import './CreateAirdrop.css'
import { Card, Col, Row } from 'antd'
// import { Col, Row } from 'reactstrap'
import Web from '../../images/sm-icon/web.svg'
import Telegram from '../../images/sm-icon/telegram.svg'
import Twitter from '../../images/sm-icon/twitter.svg'
import Discord from '../../images/sm-icon/discord.svg'
import Pancakeswap from '../../images/PancakeSwap-Logo.png'
import CoinInformationCard from '../../Components/CoinDetails/CoinInformation/CoinInformationCard'
import AirdropInformationCard from '../../Components/AirdropComponents/AirdropInformationCard'
import AirdropDescriptionCard from '../../Components/AirdropComponents/AirdropDescriptionCard'

function AirdropDetailsView() {
    return (

        <>
            <div className='container-fluid mb-5'>
                <div className='container pt-5 coin-detail-page'>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 16 }}>
                            <div>
                                <AirdropInformationCard />

                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                            <div>
                                <AirdropDescriptionCard />
                            </div>
                        </Col>
                    </Row>


                </div>
            </div>
        </>
    )
}

export default AirdropDetailsView