import React from 'react'
import { Link } from 'react-router-dom';
import { Drawer } from 'antd';
import './MobileMenu.css'
import { XCircle, Home } from 'react-feather'
import { useSelector, useDispatch } from "react-redux";
import { close } from '../../Redux/SideMenu';
import { logout } from '../../Redux/AuthSlice';

function MobileMenu() {

    const { token } = useSelector((state) => state.auth)
    const { isOpened } = useSelector((state) => state.sideMenu);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(close())
    }

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <Drawer
            placement="left"
            onClose={onClose}
            open={isOpened}
            className="front-menu"
            width="270px"
            closable={false}
        >
            <div className="row justify-content-start pt-4">

                <XCircle className="close-icon mb-3" onClick={onClose} />

                <Link to="/" className="mt-3" onClick={onClose}><span className="text-light mobile-nav-link">Home</span></Link>
                <Link to="https://nfts.rugfreecoins.com/" className="mt-3" target='_blank' onClick={onClose}><span className="text-light mobile-nav-link">NFTs</span></Link>
                <Link to="/brc-20" className="mt-3" onClick={onClose}><span className="text-light mobile-nav-link">BRC-20</span></Link>
                <Link to="/add-coin" className="mt-3" onClick={onClose}><span className="text-light mobile-nav-link">Add Tokens</span></Link>
                <Link to="/audits" className="mt-3" onClick={onClose}><span className="text-light mobile-nav-link">Audits</span></Link>
                <Link to="/events" className="mt-3" onClick={onClose}><span className="text-light mobile-nav-link">Crypto Events</span></Link>
                <Link to="https://app.rugfreecoins.com/portfolio/" className="mt-3" onClick={onClose}><span className="text-light mobile-nav-link">Portfolios</span></Link>
                <a className="mt-3" target="_blank" rel="noopener noreferrer" href="https://migrator.rugfreecoins.com"><span className="text-light mobile-nav-link">Migrator</span></a>
                <a className="mt-3" target="_blank" rel="noopener noreferrer" href="https://airdrop.rugfreecoins.com"><span className="text-light mobile-nav-link">Airdrops</span></a>
                {token ?
                    <Link to="/profile" className="mt-3" onClick={onClose}><span className="text-light mobile-nav-link">Profile</span></Link>
                    :
                    <Link to="/login" className="mt-3" onClick={onClose}><span className="text-light mobile-nav-link">Login</span></Link>
                }

                {token && <Link to="/login" className="mt-3" onClick={handleLogout}><span className="text-light mobile-nav-link">Logout</span></Link>}
            </div>
        </Drawer>
    )
}

export default MobileMenu