import React, { useEffect, useState } from 'react'
import { Card, Tabs } from 'antd'
import { FireFilled, RocketFilled, StarFilled } from '@ant-design/icons'
import EventDetailsOverview from './EventDetailsOverview'
import CoinDetailsAirdrop from '../AirdropComponents/CoinDetailsAirdrop'

function EventDetails() {

    const [activeKey, setActiveKey] = useState('tab1')

    const changeTab = activeKey => {
        setActiveKey(activeKey);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <>
            <div className='mb-5'>
                <div className='page-header'>
                    <div className='container pt-5'>
                    <EventDetailsOverview />
                    </div>
                </div>
            </div>
            {/* <>
                <div className='mb-5'>
                    <div className='container pt-5'>
                        <Tabs>
                            <Tabs.TabPane tab={<div className='d-flex'><div style={{ marginTop: '-3px', marginLeft: '10px' }}>
                                <StarFilled />
                            </div><span style={{ width: '100px' }}>Overview</span></div>}
                                key='tab1'>
                                <CoinDetailsOverview />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<div className='d-flex'><div style={{ marginTop: '-3px', marginLeft: '10px' }}>
                                <FireFilled />
                            </div><span style={{ width: '100px' }}>Airdrop</span></div>} key='tab2'>
                                <CoinDetailsOverview />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </> */}
        </>
    )
}

export default EventDetails