import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Home from './Home/Home'
import Services from './ServicesPage/Services'
import ServicesInner from './ServicesPage/ServicesInner'
import AuditPage from './AuditPage/AuditPage'
import PaidPromotion from './PaidPromotionPage/PaidPromotion'
import AuditProcessPage from './AuditPage/AuditProcessPage'
import CoinDetails from '../Components/CoinDetails/CoinDetails'
import TokenListPage from './TokensListPage/TokenListPage'
import Login from './Auth/Login'
import Register from './Auth/Register'
import AddCoin from './AddCoinPage/AddCoin'
import Profile from './Profile/Profile'
import CreateAirdrop from './Airdrop/CreateAirdrop'
import AirdropList from './Airdrop/AirdropList'
import AirdropDetailsView from './Airdrop/AirdropDetailsView'
import Watchlist from './Watchlist/Watchlist'
import Events from './CryptoEvents/Events'
import Brc20 from './Brc20/Brc20'
import EventDetails from '../Components/EventDetails/EventDetails'
import Brc20Details from '../Components/Brc20Details/Brc20Details'
import { useDispatch, useSelector } from "react-redux"
import NotFoundPage from './NotFoundPage/NotFoundPage'

function Pages() {

  // auth related variables
  const { token } = useSelector((state) => state.auth)

  return (
    <Routes>
      {/* <Route path="/" exact element={<Home />} /> */}
      <Route path="/" exact element={<TokenListPage />} />
      <Route path="/login" exact element={<Login />} />
      <Route path="/register" exact element={<Register />} />
      <Route path="/add-coin" exact element={token ? <AddCoin /> : <Navigate to="/login" />} />
      <Route path="/create-airdrop-sjjfdkjio" exact element={<CreateAirdrop />} />
      <Route path="/airdrop-list" exact element={<AirdropList />} />
      <Route path="/airdrop-details/:id" exact element={<AirdropDetailsView />} />
      <Route path="/watchlisttetjfslkjf" exact element={<Watchlist />} />
      {/* <Route path="/create-airdrop" exact element={token ? <CreateAirdrop /> : <Navigate to="/login" />} /> */}
      {/* <Route path="/services" exact element={<Services />} /> */}
      {/* <Route path="/services/inner-services" exact element={<ServicesInner />} /> */}
      <Route path="/audits" exact element={<AuditPage />} />
      <Route path="/audit" exact element={<AuditPage />} />
      <Route path="/audit-coin" exact element={<AuditPage />} />
      {/* <Route path="/paid-promotion" exact element={<PaidPromotion />} /> */}
      {/* <Route path="/tokens" exact element={<TokenListPage />} /> */}
      {/* <Route path="/audit-process" exact element={<AuditProcessPage />} /> */}
      <Route path="/coin-details/:id" exact element={<CoinDetails />} />
      <Route path="/profile" exact element={token ? <Profile /> : <Navigate to="/login" />} />
      <Route path="/events" exact element={<Events />} />
      <Route path="/brc-20" exact element={<Brc20 />} />
      <Route path="/brc-20/:id/:tokenName" exact element={<Brc20Details />} />
      <Route path="/event-details/:id" exact element={<EventDetails />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default Pages