import React, { useEffect, useState } from 'react';
import { Avatar, Comment, Empty, Card, Input, Form, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { fetchTokenData } from '../../../Redux/TokenDataSlice';
import moment from 'moment'
import { Link, useParams } from 'react-router-dom';
import api from '../../../Services/Api';

function Comments() {

    // auth related variables
    const { token, userProfile } = useSelector((state) => state.auth)
    const { id } = useParams()
    const { loading, data } = useSelector((state) => state.tokenData)
    const dispatch = useDispatch()
    const [isAddingCommentLoading, setIsAddingCommentLoading] = useState(false)

    // fetch data from API
    useEffect(() => {
        if(id) {
            dispatch(fetchTokenData(id))
        }
    }, [id])


    const onFinish = async (values) => {
        setIsAddingCommentLoading(true)
        const comment = values.comment
        const user_id = userProfile?.id
        const coin_id = id

        let formData = new FormData();

        formData.append("user_id", user_id);
        formData.append("coin_id", coin_id);
        formData.append("comment", comment);


        try {
            await api.post('/coin/comment', formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                }
            }).then((response) => {
                if (response) {
                    dispatch(fetchTokenData(id))
                }
            })
        } catch (error) {
            console.log('ERROR when adding a comment ', error)
        } finally {
            setIsAddingCommentLoading(false)
        }
    }

    return (
        <div className='m-2'>
            <Card className='comments-card mb-5' loading={loading}>
                <h4 className='coin-information-card-title'>Comments</h4>
                <div className='mt-1 mb-4'>
                    <div className='horizontal-line'></div>
                </div>

                {
                    data?.comments?.length > 0 ? (
                        data?.comments?.map((data) => (
                            <Comment
                                author={data?.user?.name}
                                avatar={<Avatar>{data?.user?.name.split(' ').map(word => word.charAt(0)).join('').toUpperCase()}</Avatar>}
                                content={data?.comment}
                                datetime={moment(data?.created_at).fromNow()}
                            />
                        ))
                    ) : (
                        <Empty description={<p>Be the first commenter!</p>} />
                    )
                }
                
                <div className='mt-3'>
                    {
                        token ? (
                            <Form
                                name="token_information"
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="comment"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please add your comment before submit!',
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        placeholder="Type your amazing thoughts"
                                        size="large"
                                        rows={3}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className='w-100' loading={isAddingCommentLoading}>
                                        Submit Your Thoughts
                                    </Button>
                                </Form.Item>
                            </Form>
                        ) : (
                            <Link to="/login">
                                <Button type="primary" className='w-100'>Login to comment</Button>
                            </Link>
                        )
                    }
                </div>
            </Card>
        </div>
    )
}

export default Comments