import { Card, Col, Row } from 'antd'
import React from 'react'
import EventTabs from './EventTabs/EventTabs'
import FavouriteTab from './EventTabs/FavoriteTab/FavouriteTab'
import './Events.css'
import AddHere from '../../Components/AddHere/AddHere'

function Events() {
    return (
        <div className='container-lg mt-5 mb-5'>
            <AddHere />
            <h3 className="text-uppercase">Crypto Events {(new Date().getFullYear())}</h3>
            <p>Explore the dynamic world of cryptocurrencies and blockchain technology through our Crypto Events  page. Stay informed and connected by browsing our carefully curated collection of upcoming events.</p>
            <Row className='mt-5'>
                <Col span={24}>
                    <Card className='home-tabs-card events-card'>
                        <EventTabs />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Events