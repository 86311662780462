import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

// initialize client IP from local storage
const clientIP = localStorage.getItem('rfc_client_ip')
    ? localStorage.getItem('rfc_client_ip')
    : null

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch comments data from API
export const fetchCryptoEventComments = createAsyncThunk('fetchCryptoEventComments', async (id) => {
    return api 
    .get(`crypto-events/event/${id}/comments`)
    .then((response) => response.data.payload)
})

export const allCryptoEventCommentSlice = createSlice({
    name: 'eventComments',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchCryptoEventComments.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchCryptoEventComments.fulfilled, (state, action) => {
            state.data = action.payload
            state.error = ''
            state.loading = false

            console.log("comments here",action.payload)
        })
        builder.addCase(fetchCryptoEventComments.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
    }
})

export default allCryptoEventCommentSlice.reducer