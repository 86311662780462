import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

// initialize client IP from local storage
const clientIP = localStorage.getItem('rfc_client_ip')
    ? localStorage.getItem('rfc_client_ip')
    : null

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch data from api
export const fetchPromotedTokens = createAsyncThunk('fetchPromotedTokens', async (pageNumber) => {
    return api
        .get(`coins/promoted?per_page=5&direction=DESC&sort_key=vote_count&ip_address=${clientIP}&page=${pageNumber}`)
        .then((response) => response.data.payload)
})

export const promotedTokensSlice = createSlice({
    name: "promotedTokens",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchPromotedTokens.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchPromotedTokens.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
        })
        builder.addCase(fetchPromotedTokens.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
    }
});

export default promotedTokensSlice.reducer