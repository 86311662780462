import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {Card, Button} from 'antd'
import './NotFoundPage.css'
import { Link } from 'react-router-dom'

function NotFoundPage() {
  return (
    <Container className='mt-5'>
        <Row>
            <Col>
                <Card className='home-tabs-card not-found-page'>
                    <div className="d-flex flex-column align-content-center justiy-content-center">
                        <div className='text-center'>
                            <h1 className='mb-1'>404</h1>
                        </div>
                        <div className='text-center'>
                            <p>Sorry, the page you are looking for cannot be found. Please check the URL or navigate back to the homepage.</p>
                            <Link to={'/'}>
                                <Button type='primary' size='large'>Homepage</Button>
                            </Link>
                            
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    </Container>
  )
}

export default NotFoundPage