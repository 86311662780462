import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./NavBar.css";
import Logo from "../../images/rfc_new_logo.svg";
import { Button, Spin, Avatar, Dropdown, Menu, Space } from "antd";
import { Menu as MenuIcon } from "react-feather";
import MobileMenu from "../MobileMenu/MobileMenu";
import { useDispatch, useSelector } from "react-redux";
import { open } from "../../Redux/SideMenu";
import { getUserProfileData, logout } from "../../Redux/AuthSlice";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { fetchClientIP } from "../../Redux/AuthSlice";
import { CaretDownOutlined } from "@ant-design/icons";

function NavBar() {
  // auth related variables
  const { token, loading, error, userProfile } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleShowMobileMenu = () => {
    dispatch(open());
  };

  // fetch client ip
  useEffect(() => {
    dispatch(fetchClientIP());
  }, []);

  // if there is a token fetch user profile details
  useEffect(() => {
    if (token) {
      setIsUserLoggedIn(true);
      dispatch(getUserProfileData(token));
    } else {
      setIsUserLoggedIn(false);
    }
  }, [token]);

  // if error occur when fetch user profile data, logout user
  useEffect(() => {
    if (error) {
      dispatch(logout());
    }
  }, [error]);

  // to navigate to user profile
  const naviateToAccount = () => {
    navigate("/profile");
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: <a onClick={naviateToAccount}>Profile</a>,
          icon: (
            <Avatar
              size="small"
              style={{ marginLeft: "-5px", backgroundColor: "#30317C" }}
              icon={
                <div style={{ marginTop: "-3px" }}>
                  <UserOutlined />
                </div>
              }
            />
          ),
        },
        {
          key: "2",
          label: <a onClick={handleLogout}>Logout</a>,
          icon: (
            <Avatar
              size="small"
              style={{ marginLeft: "-5px", backgroundColor: "#fa541c" }}
              icon={
                <div style={{ marginTop: "-3px" }}>
                  <LogoutOutlined />
                </div>
              }
            />
          ),
        },
      ]}
    />
  );

  const toolsMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://migrator.rugfreecoins.com"
            >
              Migrator
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://airdrop.rugfreecoins.com"
            >
              Airdrops
            </a>
          ),
        },
      ]}
    />
  );

  const airdropMenu = (
    <Menu
      items={[
        {
          key: "1",
          // label: <Link to="/airdrop-list"><a>Airdrop List</a></Link>,
        },
        {
          key: "2",
          // label: <Link to="/create-airdrop"><a>Create Airdrop</a></Link>,
        },
      ]}
    />
  );

  return (
    <nav className="navbar fixed-top navbar-light bg-blur-nav pt-3 pb-3 mt-4">
      <div className="container d-flex justify-content-between">
        {/* site logo */}
        <div className="navbar-brand d-flex mt-2">
          <div style={{ marginRight: "5px", marginTop: "-3px" }}>
            <Link to="https://app.rugfreecoins.com/">
              <img src={Logo} alt="main_logo" style={{ heigh: "30px" }} />
            </Link>
          </div>
        </div>

        {/* header menu links to show on large devices in center -> will hide on small devices */}
        <div
          className="d-flex hide-on-mobile center centered-navbar"
          style={{ marginLeft: "20px" }}
        >
          <ul className="list-inline">
            <li className="list-inline-item">
              {/* <a href='https://app.rugfreecoins.com/' target="_blank" className="navbarlink">
                Home
                <span className="lines" />
              </a> */}
              <Link to="/" className="navbarlink">
                Home
              </Link>
            </li>
            <li className="list-inline-item">
              <a
                href="https://nfts.rugfreecoins.com/"
                target="_blank"
                className="navbarlink"
              >
                NFTs
                <span className="lines" />
              </a>
            </li>
            <li className="list-inline-item">
              <Link to="/brc-20" className="navbarlink">
                BRC-20
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="https://rugfreecoins.com/" className="navbarlink">
                Token Listing
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="/add-coin" className="navbarlink">
                Add Tokens
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="/audits" className="navbarlink">
                Audits
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="/events" className="navbarlink">
                Crypto Events
              </Link>
            </li>
            <li className="list-inline-item">
              <Link
                to="https://app.rugfreecoins.com/portfolio/"
                className="navbarlink"
              >
                Portfolio
              </Link>
            </li>
            <li className="list-inline-item tools-menu-item">
              Tools
              <ul className="tools-dropdown-menu">
                <li className="tools-dropdown-menu-item">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://migrator.rugfreecoins.com"
                    className="drop-menu-link-item"
                  >
                    Migrator
                  </a>
                </li>
                <li className="dropdown-menu-item">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://airdrop.rugfreecoins.com"
                    className="drop-menu-link-item"
                  >
                    Airdrops
                  </a>
                </li>
              </ul>
            </li>
            {/* <li className="list-inline-item">
              <Link to="/watchlist" className="navbarlink">
                Watchlist
              </Link>
            </li> */}
          </ul>
        </div>

        {/* button & hamburger menu icon to show on large devices in right side */}
        <div>
          {!isUserLoggedIn && (
            <Link to="/login">
              <Button type="primary" className="d-flex hide-on-mobile">
                Login
              </Button>
            </Link>
          )}

          {isUserLoggedIn && loading && (
            <Spin size="small" className="hide-on-mobile" />
          )}

          {isUserLoggedIn && !loading && (
            <div className="d-flex hide-on-mobile">
              <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <h6 className="my-auto">{userProfile?.name}</h6>

                    <Avatar className="my-auto mx-2">
                      {userProfile?.name
                        .split(" ")
                        .map((word) => word.charAt(0))
                        .join("")
                        .toUpperCase()}
                    </Avatar>
                  </Space>
                </a>
              </Dropdown>
            </div>
          )}

          <MenuIcon className="hide-on-pc" onClick={handleShowMobileMenu} />
        </div>

        {/* Mobile side menu will show up when hamburger menu icon clicked */}
        <MobileMenu />
      </div>
      <div className="navbar-bottom-line" />
    </nav>
  );
}

export default NavBar;
