import React from 'react'
import { Tag } from 'antd'
import './RiskLevel.css'

function RiskLevel(props) {

    const { riskLevel } = props

    const getRiskLevelTag = () => {
        switch (riskLevel) {
            case "SAFU":
                return (<Tag className='safu-tag text-dark fw-bold'>{riskLevel?.toUpperCase()}</Tag>)
            case "Low":
                return (<Tag className='low-tag text-dark fw-bold' color={'#33C2FF'}>{riskLevel?.toUpperCase()}</Tag>)
            case "Medium":
                return (<Tag className='text-light fw-bold' color={'#9254de'}>{riskLevel?.toUpperCase()}</Tag>)
            default:
                return (<Tag className='text-light' color={'#595959'}>{riskLevel?.toUpperCase()}</Tag>)
        }
    }

    return (
        <div>
            {getRiskLevelTag()}
        </div>
    )
}

export default RiskLevel