import React from 'react'
import { Button } from 'antd'

//Components
import ServicesDetails from '../../Components/ServicesInnerComponents/ServiceDetails/ServicesDetails'
import DeliveredProjectCard from '../../Components/ServicesInnerComponents/DeliveredProjects/DeliveredProjectCard'

function ServicesInner() {
    return (
        <>
            <div className='page-header'>
                <div className='container-fluid stats-sections d-flex flex-column justify-content-center mt-2'>
                    <div className='container'>
                        <h6 className='service-card-title'>Service / Smart Contract</h6>
                    </div>
                </div>
                <div className='container pt-5'><ServicesDetails /></div>
            </div>
            <div className='container pt-3'><DeliveredProjectCard /></div>
            <div className='container-fluid stats-section d-flex flex-column justify-content-center mt-2'>
                <div className='service-button'><h2 data-aos="fade-up" data-aos-delay="50" className='heading-2'>Have a project in your mind?</h2></div>
                <div className='service-button'>
                    <Button data-aos="fade-up" data-aos-delay="50" className="animate__animated animate__fadeIn animate__delay-1s" type="primary" align='center' size='large'>Contact Us Now</Button>
                </div>
            </div>
        </>
    )
}

export default ServicesInner