import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch data from api
export const fetchUpcomingGems = createAsyncThunk('fetchUpcomingGems', async (pageNumber) => {
    return api
        .get(`coins/upcoming?per_page=10&direction=DESC&sort_key=vote_count&ip_address=null&page=${pageNumber}`)
        .then((response) => response.data.payload)
})

export const upcomingGemsSlice = createSlice({
    name: "upcomingGems",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchUpcomingGems.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchUpcomingGems.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
        })
        builder.addCase(fetchUpcomingGems.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
    }
});

export default upcomingGemsSlice.reducer