import React from 'react'
import AirdropTableTab from '../../Components/AirdropComponents/AirdropTableTab'
import { Col, Row } from 'reactstrap'
import AddHere from '../../Components/AddHere/AddHere'

function AirdropList() {
    return (
        <div className='container-lg'>
            <div className='page-header'>
                <AddHere />
                <Row>
                    <Col>

                        {/* <div className='container'> */}
                        {/* <div className='mb-4'> */}
                        <h3 className="text-uppercase">Airdrops</h3>
                        <p>To participate to an active airdrop, click on a token from the list and follow the steps in the form.</p>
                        <AirdropTableTab />
                        {/* </div> */}
                        {/* </div> */}

                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default AirdropList