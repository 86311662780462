import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

// initialize client IP from local storage
const clientIP = localStorage.getItem('rfc_client_ip')
    ? localStorage.getItem('rfc_client_ip')
    : null

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch data from api
export const fetchUpcomingEvents = createAsyncThunk('fetchUpcomingEvents', async (searchObject) => {
    return api
        .get(`crypto-events/upcoming?per_page=10&direction=ASC&sort_key=start_datetime&page=${searchObject.pageNumber}`)
        .then((response) => response.data.payload)
})

export const upComingEventsSlice = createSlice({
    name: "upComingEvents",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchUpcomingEvents.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchUpcomingEvents.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
        })
        builder.addCase(fetchUpcomingEvents.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
    }
});

export default upComingEventsSlice.reducer