import React, { useState, useEffect } from 'react'
import { Card, Tag, notification } from 'antd'
import '../CoinDetails/CoinDescription/CoinDescriptionCard.css'
import '../CoinDetails/CoinInformation/CoinInformationCard.css'
import GreenEye from '../../images/green-eye.svg'
import BlueEye from '../../images/blue-eye.svg'
import fallbackCoinImg from '../../images/no-img.png'
import moment from 'moment'
import { useParams } from 'react-router-dom';






function AirdropDescriptionCard() {

    const [imgSrc, setImgSrc] = useState(fallbackCoinImg)

    const handleImgError = () => {
        setImgSrc(fallbackCoinImg)
    }
    return (
        <div className='m-2'>
            <Card className='coin-information-card mb-5 mt-2' >
                <div className='mt-1'>
                    {/* <img className='card-image mb-3' alt='Token Logo' src={CoinIcon} /> */}
                    <h4 className='coin-information-card-title'>Airdrop Information</h4>
                    <div className='mt-1 mb-4'>
                        <div className='horizontal-line'></div>
                    </div>
                </div>
                <div>
                    <div className='coin-information-logo mb-5'>
                        <div className='pl-1 mt-2'>
                            <h5>Test 01</h5>
                            <p className='subtext1'>HDL</p>
                        </div>
                        <img src={imgSrc} alt='CoinIcon' className='card-icon-tc' onError={handleImgError} />
                    </div>
                </div>
                <div>
                    <div className='coin-information-content'>
                        <p className='ci-text'>Market Cap</p>
                        <p className='ci-text2'>897398</p>
                    </div>
                </div>
                <div>
                    <div className='coin-information-content'>
                        <p className='ci-text'>Audit</p>
                        <Tag className='ci-audit-tag-green'>By RFC <img className='green-eye-icon' src={GreenEye} alt="eye-icon" /> </Tag>
                    </div>
                </div>
                {/* <div>
            <div className='coin-information-content'>
                <p className='ci-text'>KYC</p>
                <Tag className='ci-kyc-tag-blue'>By SomeLongTXT <img className='green-eye-icon' src={BlueEye} alt="eye-icon" /></Tag>
            </div>
        </div> */}
                <div>
                    <div className='coin-information-content'>
                        <p className='ci-text'>Released</p>
                        <p className='ci-text2'>2023/10/02</p>
                    </div>
                </div>
                <div>
                    <div className='coin-information-content'>
                        <p className='ci-text'>Status</p>

                    </div>
                </div>
                {/* <div>
                    <div className='coin-information-content'>
                        <p className='ci-text'>Presale Status</p>
                       
                    </div>
                </div> */}
                {/* <div>
            <div className='coin-information-content'>
                <p className='ci-text'>Listing Status</p>
                <Tag className='ci-listing-tag'>Listed</Tag>
            </div>
        </div> */}
            </Card>
        </div>
    )
}

export default AirdropDescriptionCard