import React from 'react';
import { Button, Card, Tabs } from 'antd';
// Eventstab
import FavouriteTab from './FavoriteTab/FavouriteTab';  
import HottestTab from './HottestTab/HottestTab';
import UpcomingTab from './UpcommingTab/UpcomingTab';
import './EventTabs.css'
import { Link } from 'react-router-dom';
import EventSearch from '../EventSearchBar';
import { StarFilled, FireFilled, ClockCircleFilled } from '@ant-design/icons'
import PastEventsTab from './PastEventsTab/PastEvents';

function EventTabs() {
    const operations = <EventSearch />;
    return (

        <>
        
                <Tabs tabBarExtraContent={operations} type="card" defaultActiveKey='tab1'>
                    <Tabs.TabPane tab={<div className='d-flex'><div style={{marginTop: '-3px'}}><FireFilled /></div><span>Upcoming Events</span></div>} key='tab1'>
                        <div className='upcoming-events-tab table-responsive'>
                            <UpcomingTab />
                        </div>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={<div className='d-flex'><div style={{marginTop: '-3px'}}><ClockCircleFilled /></div><span>Past Events</span></div>} key='tab2'>
                        <div className='past-events-tab table-responsive'>
                            <PastEventsTab/>
                        </div>
                    </Tabs.TabPane>

                    {/* <Tabs.TabPane tab={<div className='d-flex'><div style={{marginTop: '-3px'}}><StarFilled /></div><span>Favorites</span></div>} key='tab3'>
                       <div className='fav-events-tab'>
                         <FavouriteTab />
                       </div>
                    </Tabs.TabPane> */}
                    
                </Tabs>
        </>
    )
}

export default EventTabs