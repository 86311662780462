import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { useSelector } from "react-redux"
import { Card, Button } from 'antd'
import UserCoinsTable from '../../Components/Tables/UserCoinsTable'
import { Link } from 'react-router-dom'
// import BannerAdd from '../../Components/AddHere/BannerAdd'
import AddHere from '../../Components/AddHere/AddHere'
function Profile() {

  const { userProfile } = useSelector((state) => state.auth)
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='container-lg'>
      <div className='mt-5'>
        <AddHere />
      </div>
      
      <Row className=' mb-5'>
        <Col>
          <div className='text-center'>
            <h5>Hi {userProfile?.name}</h5>
            <p>These are the coins added by you.</p>
          </div>

          <div className='mt-5'>
            <Card className='home-tabs-card'>
              <UserCoinsTable />
            </Card>

            <div className='text-center mt-4'>
              <Link to="/add-coin">
                <Button type="primary">Add New Coin</Button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Profile
