import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Table } from 'antd'
import NumberFormat from 'react-number-format';
import { getEllipsisTxt } from '../../Helpers/Formatters'
import moment from 'moment'


function Brc20ListedTable(props) {

    const { ticker } = props

    const [tableData, setTableData] = useState([])
    const [tableDataLoading, setTableDataLoading] = useState(false)
    const [btcValue, setBtcValue] = useState(0)
    const [isBtcValueLoading, setIsBtcValueLoading] = useState(false)
    
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
      });


    useEffect(() => {
        fetchBrc20TokenListData()
        fetchBtcValue()
        const interval = setInterval(() => {
            fetchBrc20TokenListData()
            fetchBtcValue()
        }, 60000) // fetch data every 60 seconds
        return () => clearInterval(interval)
    }, [])

    const fetchBrc20TokenListData = async () => {
        setTableDataLoading(true)
        try {
            const requestData = {
                filter: {
                  tick: ticker
                },
                start: (pagination.current - 1) * pagination.pageSize,
                limit: pagination.pageSize
              };

              axios.post('https://market-api.unisat.io/unisat-market-v2/auction/actions', requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Referer': 'https://unisat.io',
                    'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36'
                }
                })
                .then(response => {
                    // Handle the response data
                    console.log('ddddd', response.data);
                    setTableData(response.data.data.list);
                    setTableDataLoading(false)
                    setPagination(prevPagination => ({
                        ...prevPagination,
                        total: response.data.data.total
                      }));
                })
                .catch(error => {
                    // Handle the error
                    console.error(error);
                });
        } catch (error) {
          console.error(error);
          setTableDataLoading(false)
        }
      };

      const handleTableChange = (pagination) => {
        setPagination(pagination);
        fetchBrc20TokenListData();
      };

    const fetchBtcValue = async () => {
        setIsBtcValueLoading(true)
        try {
              axios.post('https://market-api.unisat.io/unisat-market-v2/auction/brc20_types', {
                
                })
                .then(response => {
                    // Handle the response data
                    console.log('btc', response.data.data.BTCPrice);
                    setBtcValue(response.data.data.BTCPrice);
                    setIsBtcValueLoading(false)
                })
                .catch(error => {
                    // Handle the error
                    console.error(error);
                    setIsBtcValueLoading(false)
                });
        } catch (error) {
          console.error(error);
          setIsBtcValueLoading(false)
        }
    };

    const columns = [
        {
            key: "inscriptionNumber",
            title: 'Inscription',
            dataIndex: "inscriptionNumber",
            align: "left",
            render: (_, record) => <span className="row-no"># {record.inscriptionNumber}</span>,
        },
        {
            key: "event",
            title: 'Event',
            fixed: 'center',
            dataIndex: "event",
            align: "center",
            render: (_, record) => 
                <span className={`${record.event === "Listed" ? 'green-span' : record.event === "Sold" ? 'red-span' : ''}`}>
                    {record.event}
                </span>
        },
        {
            key: "price",
            title: "Price",
            fixed: 'left',
            align: "left",
            className: 'price-col',
            width: '20%',
            render: (_, record) => {
                const formattedValue = (parseFloat(record.unitPrice) * parseFloat(btcValue)) / 210000000
                const suffix = `sats / ` + ticker

                  return (
                    <>
                        <div className="d-flex">
                            <NumberFormat
                                displayType='text'
                                decimalScale={2}
                                value={record.unitPrice}
                                thousandSeparator={true}
                            />
                        </div>
                        <div className="chart-price-values">
                                <div>
                                    <span>{suffix}</span>
                                </div>
                                <div>
                                    <NumberFormat
                                        displayType='text'
                                        decimalScale={2}
                                        value={formattedValue}
                                        prefix='$'
                                        thousandSeparator={true}
                                    />
                                </div>
                            </div>
                    </>
                  )
            },
        },
        {
            key: "amount",
            title: 'Quantity',
            dataIndex: "amount",
            align: "left",
            render: (_, record) => <span className="">{record.amount}</span>,
            
        },
        {
            key: "total",
            title: "Total Value",
            className: "total-val-col",
            fixed: 'left',
            align: "left",
            width: '20%',
            render: (_, record) => {
                const formattedValue = (parseFloat(record.price) * parseFloat(btcValue)) / 210000000
                const suffix = `sats`

                // const price = parseFloat(record.price) * parseFloat(record.unitPrice)

                  return (
                    <>
                        <div className="d-flex">
                            <NumberFormat
                                displayType='text'
                                decimalScale={2}
                                value={record.price}
                                thousandSeparator={true}
                            />
                            
                        </div>
                        <div className="chart-price-values">
                                <div>
                                    <span>{suffix}</span>
                                </div>
                                <div>
                                    <NumberFormat
                                        displayType='text'
                                        decimalScale={2}
                                        value={formattedValue}
                                        prefix='$'
                                        thousandSeparator={true}
                                    />
                                </div>
                            </div>
                            
                    </>
                  )
            },
        },
        {
            key: "from",
            title: 'From',
            dataIndex: "from",
            align: "left",
            render: (_, record) => <span className="">{getEllipsisTxt(record.from, 5)}</span>,
            
        },
        {
            key: "to",
            title: 'To',
            dataIndex: "to",
            align: "left",
            render: (_, record) => <span className="">{record.to ? getEllipsisTxt(record.to, 5) : '-'}</span>,
            
        },
        {
            key: "time",
            title: 'Time',
            dataIndex: "time",
            align: "right",
            render: (_, record) => <span className="">{moment(record.timestamp).format('DD / MM / YYYY, HH:MM:SS')}</span>,
            
        },
        
    ];

    return (
        <div>
            <div className='table'>
                <div className='table-responsive'>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                        size='large'
                        columns={columns}
                        dataSource={tableData}
                        pagination={pagination}
                        onChange={handleTableChange}
                        loading={tableDataLoading}
                        className='listed-table'
                    />
                </div>
            </div>
        </div>
    )
}

export default Brc20ListedTable;
