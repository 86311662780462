import React, { useEffect } from 'react'
import { Card } from 'antd'
import PromotedTokensSection from '../../Components/TokenListPageComponents/PromotedTokensSection'
import MostTrustedCoinSection from '../../Components/TokenListPageComponents/MostTrustedCoinSection'
import FeaturedAuditsSectionHeader from '../../Components/TokenListPageComponents/FeaturedAuditsSectionHeader'
import FeaturedAuditsSection from '../../Components/TokenListPageComponents/FeaturedAuditsSection'
import AllTokensSection from '../../Components/TokenListPageComponents/AllTokensSection'
import AllTokenListTab from '../../Components/AllTokenListComponents/AllTokenListTab'
import PromotedTokensData from '../../Components/Tables/PromotedTokensData.js'
import MostTrustedCoinTable from '../../Components/Tables/MostTrustedCoinTable'
import './TokenListPage.css'
import { Col, Row } from 'reactstrap'
import AddHere from '../../Components/AddHere/AddHere'
// import BannerAdd from '../../Components/AddHere/BannerAdd'
import TokenStats from '../../Components/TokenStats/TokenStats'

function TokenListPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='container-lg'>
      <div className='page-header'>
        
        {/* <BannerAdd /> */}
        <AddHere />
        <Row className='mt-2 mb-4'>
          <Col>
          <TokenStats />
          </Col>
        </Row>
        
        <Row>
          <Col>
            <PromotedTokensSection />
            <Card className='home-tabs-card mb-5'>
              <PromotedTokensData />
            </Card>
          </Col>
        </Row>

        {/* <Row>
          <Col>
            <MostTrustedCoinSection />
            <Card className='home-tabs-card mb-5'>
              <MostTrustedCoinTable />
            </Card>
          </Col>
        </Row> */}

        <Row>
            <Col>
              <FeaturedAuditsSectionHeader />
              <FeaturedAuditsSection />
            </Col>
          </Row>

        <Row>
          <Col>
            <AllTokensSection />
            <AllTokenListTab />
          </Col>
        </Row>

        <AddHere />
      </div>
    </div>
  )
}

export default TokenListPage