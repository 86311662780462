import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

// initialize client IP from local storage
const clientIP = localStorage.getItem('rfc_client_ip')
    ? localStorage.getItem('rfc_client_ip')
    : null

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch data from api
export const fetchBrc20TokensNew = createAsyncThunk('fetchBrc20TokensNew', async (pageNumber) => {
    const config = {
        axiosHeaders: {
            'Content-Type': 'application/json',
            'x-access-token': 'coinranking4725064f7c597827659d17dded97fe7c8db95aed015b4523',
        }

    };
    const response = await axios.get(
        // `https://api.ordspace.org/brc-20?page=${pageNumber}&orderBy=market&s=`
        `https://api.coinranking.com/v2/coins?limit=50&timePeriod=24h&search=&tags[]=brc-20`, config
    )
    //   console.log('brc20', response);

    //   return response.data.code != 200 ? [] : response.data.data

    return response.data
})



const brc20TokensSliceNew = createSlice({
    name: "brc20TokensDataNew",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchBrc20TokensNew.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchBrc20TokensNew.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data.coins
            state.error = ''
            // console.log(action.payload.data)
        })
        builder.addCase(fetchBrc20TokensNew.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error
        })
    }
});

export default brc20TokensSliceNew.reducer