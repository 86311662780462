import React, { useEffect, useState } from 'react';
import { Avatar, Comment, Empty, Card, Input, Form, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
//import { fetchTokenData } from '../../../Redux/TokenDataSlice';
import { fetchCryptoEventComments } from '../../../Redux/EventCommentsDataSlice';
import moment from 'moment'
import { Link, useParams } from 'react-router-dom';
import api from '../../../Services/Api';

function Comments() {

    // auth related variables
    const { token, userProfile } = useSelector((state) => state.auth)
    const { id } = useParams()
    const { loading, data } = useSelector((state) => state.eventComments)
    const dispatch = useDispatch()
    const [isAddingCommentLoading, setIsAddingCommentLoading] = useState(false)

    // fetch data from API
    useEffect(() => {
        if (id) {
            dispatch(fetchCryptoEventComments(id))
        }
    }, [id])

    // console.log(userProfile?.name);
    console.log('profile id', userProfile?.id);

    const onFinish = async (values) => {
        setIsAddingCommentLoading(true)
        const comment = values.comment
        const user_id = userProfile?.id
        const event_id = id

        let commentFormData = new FormData();

        

        commentFormData.append("user_id", user_id);
        commentFormData.append("event_id", event_id);
        commentFormData.append("comment", comment);

        console.log('values', values);


        try {
            await api.post('crypto-events/comment', commentFormData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                }
            }).then((response) => {
                if (response) {
                    dispatch(fetchCryptoEventComments(id))
                }
            })
        } catch (error) {
            console.log('ERROR when adding a comment ', error)
        } finally {
            setIsAddingCommentLoading(false)
        }
    }
    console.log("data commnet", data)

    return (
        <div className='m-2'>
            <Card className='comments-card mb-5' loading={loading}>
                <h4 className='coin-information-card-title'>Comments</h4>
                <div className='mt-1 mb-4'>
                    <div className='horizontal-line'></div>
                </div>

                {
                    data?.length > 0 ? (
                        data?.map((dataItem) => (
                            <>
                            {/* <span>{alert(dataItem?.user[0])}</span> */}
                            <Comment
                                author={dataItem?.user?.name}
                                avatar={<Avatar>{dataItem?.user.name.split(' ').map(word => word.charAt(0)).join('').toUpperCase()}</Avatar>}
                                content={dataItem?.comment}
                                datetime={moment(dataItem?.created_at).fromNow()}
                            />
                            </>
                        ))
                    ) : (
                        <Empty description={<p>Be the first commenter!</p>} />
                    )
                }

                <div className='mt-3'>
                    {
                        token ? (
                            <Form
                                name="comment_information"
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="comment"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please add your comment before submit!',
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        placeholder="Type your amazing thoughts"
                                        size="large"
                                        rows={3}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className='w-100' loading={isAddingCommentLoading}>
                                        Submit Your Thoughts
                                    </Button>
                                </Form.Item>
                            </Form>
                        ) : (
                            <Link to="/login">
                                <Button type="primary" className='w-100'>Login to comment</Button>
                            </Link>
                        )
                    }
                </div>
            </Card>
        </div>
    )
}

export default Comments