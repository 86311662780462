import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

// initialize client IP from local storage
const clientIP = localStorage.getItem('rfc_client_ip')
    ? localStorage.getItem('rfc_client_ip')
    : null

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch data from api
export const fetchCryptoEventData = createAsyncThunk('fetchCryptoEventData', async (id) => {
    return api
        .get(`crypto-events/${id}`)
        .then((response) => response.data.payload)
})

export const allCryptoEventListSlice = createSlice({
    name: "eventData",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchCryptoEventData.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchCryptoEventData.fulfilled, (state, action) => {
            state.data = action.payload
            state.error = ''
            state.loading = false

            console.log("action here",action.payload)
        })
        builder.addCase(fetchCryptoEventData.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
    }
});

export default allCryptoEventListSlice.reducer