import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch data from api
export const fetchDoxxedTokens = createAsyncThunk('fetchDoxxedTokens', async (pageNumber) => {
    return api
        .get(`coins/doxxed/upcoming?per_page=10&direction=DESC&sort_key=vote_count&ip_address=null&page=${pageNumber}`)
        .then((response) => response.data.payload)
})

export const doxxedTokensSlice = createSlice({
    name: "doxxedTokens",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchDoxxedTokens.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchDoxxedTokens.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
        })
        builder.addCase(fetchDoxxedTokens.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
    }
});

export default doxxedTokensSlice.reducer