import React from 'react'

function PromotedTokensSection() {
  return (
    <div>
      <h3 className="text-uppercase">Promoted Coins</h3>
      <p>Only audited coins are listed in the paid promotions section.</p>
      {/* <div className='divider'></div> */}
    </div>
  )
}

export default PromotedTokensSection