import { Card, Tag, notification } from 'antd'
import React, { useState, useEffect } from 'react'
import './CoinInformationCard.css'
import GreenEye from '../../../images/green-eye.svg'
import BlueEye from '../../../images/blue-eye.svg'
import fallbackCoinImg from '../../../images/no-img.png'
import MarketCap from '../../MarketCap/MarketCap'
import moment from 'moment'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { fetchTokenData } from '../../../Redux/TokenDataSlice';

function CoinInformationCard(props) {

    const { id } = useParams()
    const { loading, data, error } = useSelector((state) => state.tokenData)
    const [imgSrc, setImgSrc] = useState(fallbackCoinImg)
    const dispatch = useDispatch()

    // antd notifications without duplicating
    const notificatioKey = 'error';

    // fetch data from API
    useEffect(() => {
        if(id) {
            dispatch(fetchTokenData(id))
        }
    }, [id])

    // error nofitications
    useEffect(() => {
        if (error) {
            notification['error']({
                key: notificatioKey,
                message: 'Oops!',
                description: error
            });
        }
    }, [error])

    useEffect(() => {
        setImgSrc(data?.logo_link)
    }, [data?.logo_link])

    const handleImgError = () => {
        setImgSrc(fallbackCoinImg)
    }

    return (
        <div className='m-2'>
            <Card className='coin-information-card mb-5 mt-2' loading={loading}>
                <div className='mt-1'>
                    {/* <img className='card-image mb-3' alt='Token Logo' src={CoinIcon} /> */}
                    <h4 className='coin-information-card-title'>Token Information</h4>
                    <div className='mt-1 mb-4'>
                        <div className='horizontal-line'></div>
                    </div>
                </div>
                <div>
                    <div className='coin-information-logo mb-5'>
                        <div className='pl-1 mt-2'>
                            <h5>{data?.name}</h5>
                            <p className='subtext1'>{data?.symbol}</p>
                        </div>
                        <img src={imgSrc} alt='CoinIcon' className='card-icon-tc' onError={handleImgError} />
                    </div>
                </div>
                <div>
                    <div className='coin-information-content'>
                        <p className='ci-text'>Market Cap</p>
                        <p className='ci-text2'>{<MarketCap address={data?.bsc_contract_address ? data?.bsc_contract_address : data?.ethereum_contract_address} chain={data?.bsc_contract_address ? 'bsc' : 'eth'} />}</p>
                    </div>
                </div>
                <div>
                    <div className='coin-information-content'>
                        <p className='ci-text'>Audit</p>
                        <Tag className='ci-audit-tag-green'>By RFC <img className='green-eye-icon' src={GreenEye} alt="eye-icon" /> </Tag>
                    </div>
                </div>
                {/* <div>
                    <div className='coin-information-content'>
                        <p className='ci-text'>KYC</p>
                        <Tag className='ci-kyc-tag-blue'>By SomeLongTXT <img className='green-eye-icon' src={BlueEye} alt="eye-icon" /></Tag>
                    </div>
                </div> */}
                <div>
                    <div className='coin-information-content'>
                        <p className='ci-text'>Released</p>
                        <p className='ci-text2'>{moment(data?.release_date).fromNow()}</p>
                    </div>
                </div>
                <div>
                    <div className='coin-information-content'>
                        <p className='ci-text'>Status</p>
                        <Tag className={ data?.status === "active" ? 'ci-status-tag' : 'ci-presale-status-tag' }>{data?.status}</Tag>
                    </div>
                </div>
                <div>
                    <div className='coin-information-content'>
                        <p className='ci-text'>Presale Status</p>
                        <Tag className={ data?.is_presale === parseInt(0) ? 'ci-presale-status-tag' : 'ci-status-tag' }>{data?.is_presale === parseInt(0) ? 'inactive' : 'active'}</Tag>
                    </div>
                </div>
                {/* <div>
                    <div className='coin-information-content'>
                        <p className='ci-text'>Listing Status</p>
                        <Tag className='ci-listing-tag'>Listed</Tag>
                    </div>
                </div> */}
            </Card>
        </div>
    )
}

export default CoinInformationCard