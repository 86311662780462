import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

const initialState = {
    loading: false,
    success: false,
    error: false
}

export const addCoin = createAsyncThunk('/coin', async (formData) => {

    return api
        .post("/coin", {
            body: formData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then((response) => response)
})

export const addCoinSlice = createSlice({
    name: "addCoin",
    initialState,
    reducers: {
        reset: (state) => {
            state.success = false
            state.error = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addCoin.pending, (state) => {
            state.loading = true
            state.success = false
            state.error = false
        })
        builder.addCase(addCoin.fulfilled, (state, action) => {
            state.loading = false
            state.error = false
            state.success = true
        })
        builder.addCase(addCoin.rejected, (state, action) => {
            state.loading = false
            state.success = false
            state.error = true
        })
    }
})

export const { reset } = addCoinSlice.actions

export default addCoinSlice.reducer