import React from "react";
import "../CoinDetails/CoinDescription/CoinDescriptionCard.css";
import "../CoinDetails/CoinInformation/CoinInformationCard.css";
import { Card } from "antd";
import { Col, Row } from "reactstrap";
import Web from "../../images/sm-icon/web.svg";
import Telegram from "../../images/sm-icon/telegram.svg";
import Twitter from "../../images/sm-icon/twitter.svg";
import Discord from "../../images/sm-icon/discord.svg";
import Pancakeswap from "../../images/PancakeSwap-Logo.png";
import { Link } from "react-router-dom";

function AirdropInformationCard() {
  return (
    <div className="m-2">
      <Card className="coin-description-card mb-5">
        <div className="mb-2">
          <Row>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <div className="d-flex">
                <img src={Discord} alt="CoinIcon" className="card-icon-tc" />
                <div className="mt-3">
                  <h5>ETest</h5>
                  <p className="subtext1">Test</p>
                </div>
              </div>
            </Col>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <div className="text-md-end">
                {/* <Address tokenData={data} /> */}
                sdfkjjdkfljsldfjljlsdjlfjs
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-3">
          <Row>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12" className="mt-3">
              <div className="mt-1">
                <a target="_blank">
                  <img className="sm-icon" src={Web} alt="" />
                </a>
                <a target="_blank">
                  <img className="sm-icon" src={Telegram} alt="" />
                </a>
                <a target="_blank">
                  <img className="sm-icon" src={Twitter} alt="" />
                </a>
                <a target="_blank">
                  <img className="sm-icon" src={Discord} alt="" />
                </a>
                <a target="_blank">
                  <img
                    className="sm-icon"
                    src={Pancakeswap}
                    alt=""
                    style={{ width: "25px", height: "25px" }}
                  />
                </a>
              </div>
            </Col>
            {/* <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
                    <div className='d-md-flex justify-content-end'>
                        <Button className={`d-flex mt-3 ${voteButtonClass}`} style={{ marginRight: '5px' }} onClick={() => handleVote()} loading={isLoading}>
                            <div style={{ marginTop: '-2px', marginRight: '5px' }}>
                                <FireFilled style={{ fontSize: '14px', color: '#FFA31A' }} />
                            </div>
                            {data?.vote_count}
                        </Button>
                        <Button style={{ marginRight: '5px' }} className="mt-3 add-to-watchlist-info-card"><img src={StarIcon} alt='Star' className='star-icon' /> Add to watchlist</Button>
                        <div style={{ marginTop: '18px' }}>
                            <Badges isAudited={data?.is_audited} riskLevel={data?.risk_level} />
                        </div>
                    </div>
                </Col> */}
          </Row>
        </div>
        <div className="mt-4">
          <div className="horizontal-line"></div>
        </div>
        <div className="mt-3">
          {/* {data?.description && parse(data?.description)} */}
          but the model it runs on begs FEG to succeed. FEG is a deflationary
          token with a max circulating supply of 100 Quadrillion. On each
          transaction, a tax of 1% will be distributed to the holders and a
          further 1% will be burnt, hence incentivizing holders to hodl and
          decreasing the supply overtime. As the supply decreases, the scarcity
          of the token increases. This inversely-proportional relationship
          constitutes a supply and demand model. Furt
        </div>

        <div className="mt-5">
          <span>
            <img src={Discord} alt="Infomations" />
            <span> Information incorrect? Please submit an</span>
            <Link
              style={{ color: "#F49200" }}
              to="https://t.me/RugFreeCoins_Marketing"
              target="_blank"
            >
              {" "}
              Update Request!
            </Link>
          </span>
        </div>
      </Card>
    </div>
  );
}

export default AirdropInformationCard;
