import React from 'react'
import { Row } from 'reactstrap'
import FeaturedAuditsSection from '../../TokenListPageComponents/FeaturedAuditsSection'

function AuditCardsTab() {
    return (
        <FeaturedAuditsSection />
    )
}

export default AuditCardsTab