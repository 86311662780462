import React from 'react'
import { Row, Col, Button } from 'antd'
import './TechStackSection.css'
import techStack from '../../../images/tech-stack.png'

function TechStackSection() {
  return (
    <div className='container'>
      <div className='row'>
        <Row justify="center" align="middle">
          <Col className='text-center py-5' sm={24} md={24}>
            <h2 data-aos="revealing-animate" className='heading-2'>Developing with the Best</h2>
          </Col>
        </Row>
        <Row justify="center" align="middle" className="tech-row">

          <Col sm={{ span: 7 }}>
            <div className="img-wrapper">
              <img src='https://via.placeholder.com/80' />
            </div>
            <div className="text-wrapper">
              <h4>Title Here</h4>
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </Col>

          <Col sm={{ span: 7 }}>
            <div className="img-wrapper">
              <img src='https://via.placeholder.com/80' />
            </div>
            <div className="text-wrapper">
              <h4>Title Here</h4>
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </Col>

          <Col sm={{ span: 7 }}>
            <div className="img-wrapper">
              <img src='https://via.placeholder.com/80' />
            </div>
            <div className="text-wrapper">
              <h4>Title Here</h4>
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </Col>

          
        </Row>

        <Row justify="center" align="middle" className="tech-row">

          <Col sm={{ span: 7 }}>
            <div className="img-wrapper">
              <img src='https://via.placeholder.com/80' />
            </div>
            <div className="text-wrapper">
              <h4>Title Here</h4>
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </Col>

          <Col sm={{ span: 7 }}>
            <div className="img-wrapper">
              <img src='https://via.placeholder.com/80' />
            </div>
            <div className="text-wrapper">
              <h4>Title Here</h4>
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </Col>

          <Col sm={{ span: 7 }}>
            <div className="img-wrapper">
              <img src='https://via.placeholder.com/80' />
            </div>
            <div className="text-wrapper">
              <h4>Title Here</h4>
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </Col>

          
        </Row>

        <Row justify="center" align="middle" className="tech-row">

          <Col sm={{ span: 7 }}>
            <div className="img-wrapper">
              <img src='https://via.placeholder.com/80' />
            </div>
            <div className="text-wrapper">
              <h4>Title Here</h4>
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </Col>

          <Col sm={{ span: 7 }}>
            <div className="img-wrapper">
              <img src='https://via.placeholder.com/80' />
            </div>
            <div className="text-wrapper">
              <h4>Title Here</h4>
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </Col>

          <Col sm={{ span: 7 }}>
            <div className="img-wrapper">
              <img src='https://via.placeholder.com/80' />
            </div>
            <div className="text-wrapper">
              <h4>Title Here</h4>
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </Col>

          
        </Row>
      </div>
    </div>
  )
}

export default TechStackSection