import React from 'react'
import { Input, Space } from 'antd';
import { useSelector } from 'react-redux'

function EventSearch(props) {
  const { Search } = Input;
  const { setSearchTerm } = props;
  const { loading } = useSelector((state) => state.upComingEvents)

  const onSearch = (value) => {
    // setSearchTerm(value)
  }

  return (
    <Space direction="vertical">
      <Search placeholder="Event name" onSearch={onSearch} enterButton loading={loading} allowClear size="large" />
    </Space>
  );
}

export default EventSearch