import React, { useEffect } from 'react'
import TokenListItem from '../../TokenListItem/TokenListItem';
import { Row, Col, Table, notification, Skeleton } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { fetchRecentlyAddedTokens } from '../../../Redux/RecentlyAddedTokenSlice';

function RecentlyAdded() {

  const { loading, data, error } = useSelector((state) => state.recentltAddedTokens)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRecentlyAddedTokens())
  }, [dispatch])

  // antd notifications without duplicating
  const notificatioKey = 'error';

  // error nofitications
  useEffect(() => {
    if (error) {
      notification['error']({
        key: notificatioKey,
        message: 'Oops!',
        description: error
      });
    }
  }, [error])

  // console.log('recent data', data)


  return (
    <div>
      {
        loading ?
          <div>
            <Skeleton loading={loading} avatar active paragraph={{ rows: 0, }} />
            <Skeleton loading={loading} avatar active paragraph={{ rows: 0, }} />
            <Skeleton loading={loading} avatar active paragraph={{ rows: 0, }} />
          </div> :
          data.map((item, index) => (
            index < 3 ? <TokenListItem key={index} itemData={item} type={'recent'} /> : ''
          ))}
    </div>
  )
}

export default RecentlyAdded