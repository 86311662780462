import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch data from api
export const fetchUserTokens = createAsyncThunk('fetchUserTokens', async (id) => {
    const token = localStorage.getItem('rfc_token')
    ? localStorage.getItem('rfc_token')
    : null
    return api
        .get(`coin/me/${id}`, {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        .then((response) => response.data.payload)
})

export const userTokensSlice = createSlice({
    name: "userTokens",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchUserTokens.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchUserTokens.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
        })
        builder.addCase(fetchUserTokens.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
    }
});

export default userTokensSlice.reducer