import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Services/Api";

// initialize client IP from local storage
const clientIP = localStorage.getItem("rfc_client_ip")
  ? localStorage.getItem("rfc_client_ip")
  : null;

const initialState = {
  loading: false,
  data: [],
  error: "",
};

// fetch data from api
export const fetchAllTimeBestTokens = createAsyncThunk(
  "fetchAllTimeBestTokens",
  async (searchObject) => {
    return api
      .get(
        `coins?per_page=${
          20 * searchObject.pageNumber
        }&direction=DESC&sort_key=vote_count&ip_address=${clientIP}&page=${1}&search=${
          searchObject.searchTerm
        }`
      )
      .then((response) => response.data.payload.data);
  }
);

export const allTimeBestTokensSlice = createSlice({
  name: "allTimeBestTokens",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllTimeBestTokens.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllTimeBestTokens.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchAllTimeBestTokens.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default allTimeBestTokensSlice.reducer;
