import React from "react";
import { Card, Button, Progress, Tooltip, Image } from "antd";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import TokensAuditIconsGroup from "../TokenAuditIconsGroup/TokensAuditIconsGroup";
import Chain from "../Chain/Chain";
import ChainName from "../Chain/ChainName";
import moment from "moment";

function FeaturedAuditsCard({ audit }) {
  return (
    <Card className="featured-audits-card">
      <Row>
        <Col xxl="8" xl="8" lg="8" md="8" sm="8" xs="8" className="mt-1 mb-1">
          <div className="mb-2">
            <div className="d-flex">
              <Tooltip title="Trust Score">
                <Progress
                  type="circle"
                  width={24}
                  percent={audit?.trust_score || 0}
                  format={(percent) => `${percent}`}
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  }}
                  style={{ marginTop: "3px", marginRight: "10px" }}
                />
              </Tooltip>

              {/* chain logo */}
              <Chain tokenData={audit?.coin} />
              {/* chain name */}
              <ChainName tokenData={audit?.coin} />
            </div>
          </div>
          <Link to={`/coin-details/${audit?.coin?.id}`}>
            <h5>{audit?.coin?.name}</h5>
          </Link>
          <span className="text-muted">
            {moment(audit?.created_at).format(" YYYY-MM-DD")}
          </span>

          <div className="mt-2 mb-2">
            <TokensAuditIconsGroup audit={audit} />
          </div>

          <Button
            type="primary"
            className="mt-2"
            onClick={() => {
              window.open(
                audit?.coin?.report_link ? audit?.coin?.report_link : "#"
              );
            }}
          >
            Audit Report
          </Button>
        </Col>

        {audit?.coin?.logo_link && (
          <Col
            xxl="4"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="4"
            className="my-auto text-center"
          >
            <Image
              width={50}
              height={50}
              alt="token-logo"
              className="featured-audited-token"
              src={audit?.coin?.logo_link}
              preview={false}
              placeholder={
                <Image
                  preview={false}
                  className="featured-audited-token"
                  src="https://i.postimg.cc/SRCHGKM4/72032-200.png"
                  width={50}
                  height={50}
                />
              }
              fallback="https://i.postimg.cc/SRCHGKM4/72032-200.png"
            />
          </Col>
        )}
      </Row>
    </Card>
  );
}

export default FeaturedAuditsCard;
