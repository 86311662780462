import { Button, Card, Col, Row, Tag } from 'antd'
import './MostTrustedCoinCard.css'
import CoinIcon from '../../../images/versatile.svg'
// import FireIcon from '../../../images/fire-icon.svg'
import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import VoteButton from '../../VoteButton/VoteButton'

function MostTrustedCoinCard() {
    return (
        <>
            <Card className='trusted-coin-card mb-5 mt-2 pt-1 pb-1'>
                <h3 className='mt-2'>The Most Trusted Token</h3>
                <p className='mb-2'>The most unique & legit coin today</p>
                <div className='card-image-mt mb-4'>
                    <Row>
                        <Col span={8}>
                            <img src={CoinIcon} alt='CoinIcon' className='card-icon-tc' />
                        </Col>
                        <Col span={16}>
                            <div className='pl-1 pt-3'>
                                <h5>Versatile Finance</h5>
                                <p className='subtext1'>$VERSA</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='row'>
                    <Row>
                        <Col span={8}>
                            <div className='pt-2'>
                                <span className='sub-headers'>Market Cap</span>
                                <h6>$600K</h6>
                            </div>
                            <div className='pt-3'>
                                <span className='sub-headers'>Risk Level</span>
                                <div>
                                    <Tag className='risk-level-button mt-1'>SAFU</Tag>
                                </div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className='line-separator' />
                        </Col>
                        <Col span={12}>
                            <div className='pt-2'>
                                <span className='sub-headers'>Released</span>
                                <h6>In 6 hours</h6>
                            </div>
                            <div className='pt-3'>
                                <span className='sub-headers'>Audit Report</span>
                                <div>
                                    <Button type='link' className='button-text-color'>
                                        {/* <Avatar className='p-3 download-icon' src={DownloadIcon} /> */}
                                        <VerticalAlignBottomOutlined style={{ verticalAlign: 'middle', fontSize: '110%', marginTop: '-3px' }} />
                                        Download</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='pt-3 pb-5'>
                    <div className='mt-4'>
                        <VoteButton voteCount={232323} />
                    </div>
                </div>
            </Card>
        </>
    )
}

export default MostTrustedCoinCard