import {
  CheckCircleFilled,
  WarningFilled,
  BulbFilled,
  ThunderboltFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import React from "react";
import { Tooltip } from "antd";

function TokensAuditIconsGroup({ audit }) {
  return (
    <div className="d-flex">
      <Tooltip title="Smart contract functional Status">
        {audit?.sm_functional_status ? (
          <CheckCircleFilled
            style={{ color: "#389e0d", fontSize: "18px", marginRight: "7px" }}
          />
        ) : (
          <CloseCircleFilled style={{ color: "#d4380d", fontSize: "18px" }} />
        )}
      </Tooltip>

      <Tooltip title="Number of risk issues" className="mx-2">
        <div className="d-flex">
          <WarningFilled style={{ color: "#cf1322", fontSize: "18px" }} />
          <div
            style={{ marginTop: "-2px", marginLeft: "3px" }}
            className="fw-bold"
          >
            {audit?.risk_count}
          </div>
        </div>
      </Tooltip>

      <Tooltip title="Solidity code functional issue level" className="mx-2">
        {audit?.code_functional_issues ? (
          <CheckCircleFilled style={{ color: "#08979c", fontSize: "18px" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#d4380d", fontSize: "18px" }} />
        )}
      </Tooltip>

      <Tooltip title="Number of owner privileges" className="mx-2">
        <div className="d-flex">
          <BulbFilled style={{ color: "#c41d7f", fontSize: "18px" }} />
          <div
            style={{ marginTop: "-2px", marginLeft: "3px" }}
            className="fw-bold"
          >
            {audit?.owner_privileges}
          </div>
        </div>
      </Tooltip>

      <Tooltip
        title="Centralization risk correlated to the active owner"
        className="mx-2"
      >
        <div className="d-flex">
          <ThunderboltFilled style={{ color: "#d48806", fontSize: "18px" }} />
          <div
            style={{ marginTop: "-2px", marginLeft: "3px" }}
            className="fw-bold"
          >
            {audit?.centralization_risk}
          </div>
        </div>
      </Tooltip>

      <Tooltip title="Smart contract active ownership" className="mx-2">
        {audit?.active_ownership ? (
          <CheckCircleFilled
            style={{ color: "#389e0d", fontSize: "18px", marginRight: "7px" }}
          />
        ) : (
          <CloseCircleFilled style={{ color: "#d4380d", fontSize: "18px" }} />
        )}
      </Tooltip>
    </div>
  );
}

export default TokensAuditIconsGroup;
